import React from 'react';
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Autocomplete from '@mui/material/Autocomplete';

const SelectTextField = (props) => {
    const [inVal, setInVal] = React.useState(props.state);
    const handleChange = (event) => {
        props.setState(event.target.value);
        setInVal(event.target.value);
    }
    React.useEffect(() => {
        if (props.checkID && props.list) {
            var matched = props.list.find((dr) => { return (dr.id === props.checkID) });
            if (matched) {
                props.setState(matched);
            }
            setInVal(matched);
        }
    }, [props.list, props.checkID])
    return <TextField
        id="outlined-select-currency"
        select
        key={props.key}
        label={props.title}
        disabled={props.disabled}
        value={inVal}
        onChange={handleChange}
        InputLabelProps={{
            shrink: true,
        }}
        style={props.style}
    >

        {props.list ? props.list.map((option) => (
            <MenuItem key={option.id} value={props.valueFunc ? props.valueFunc(option) : option}>
                {props.nameFunc ? props.nameFunc(option) : option.name}
            </MenuItem>
        )) : []}
    </TextField>
}
export default SelectTextField;

export const SelectTextFieldNew = (props) => {

    const handleChange = (event) => {
        console.log(event.target.value);
        props.setState(props.list.find((l) => { return (props.valueFunc ? props.valueFunc(l) : l) === event.target.value }));
    }
    const autoOnChange = (event, newVal) => {
        handleChange({ target: { value: newVal?.id } });
    }
    var inVal = React.useMemo(() => {
        if (props.checkID && props.list) {
            var matched = props.list.find((dr) => { return (dr.id === props.checkID) });
            if (matched) {
                props.setState(matched);
            }
            return matched;
        } else {
            return props.state;
        }
    }, [props.list, props.checkID, props.state]);
    if (props.useAutoComplete) {
        return <Autocomplete
            disablePortal
            key={props.key}
            label={props.title}
            options={props.list ? props.list : []}
            value={inVal||null}
            getOptionLabel={(option) => {console.log(option);return (option?(props.nameFunc ? props.nameFunc(option) : option.name):null)}}
            onChange={autoOnChange}
            sx={props.style}
            renderInput={(params) => <TextField style={props.style} {...params} label={props.title} />}
        />
    } else {
        return <TextField
            id="outlined-select-currency"
            select
            key={props.key}
            label={props.title}
            disabled={props.disabled}
            value={(props.valueFunc ? props.valueFunc(inVal) : inVal) || ''}
            onChange={handleChange}
            InputLabelProps={{
                shrink: true,
            }}
            InputProps={{
                style: props.style
            }}
            style={props.style}
        >

            {props.list ? props.list.map((option) => (
                <MenuItem key={option.id} value={props.valueFunc ? props.valueFunc(option) : option} >
                    {props.nameFunc ? props.nameFunc(option) : option.name}
                </MenuItem>
            )) : []}
        </TextField>
    }
}
//same function as above but using autocomplete from mui instead of textfield
export const SelectTextFieldAuto = (props) => {
    const [inVal, setInVal] = React.useState(props.state);
    const handleChange = (event, value) => {
        props.setState(value);
        setInVal(value);
    }
    React.useEffect(() => {
        if (props.checkID && props.list) {
            var matched = props.list.find((dr) => { return (dr.id === props.checkID) });
            if (matched) {
                props.setState(matched);
            }
            setInVal(matched);
        }
    }, [props.list, props.checkID])
    console.log(props.list)
    return <Autocomplete
        disablePortal
        key={props.key}
        label={props.title}
        options={props.list ? props.list : []}
        getOptionLabel={(option) => (props.nameFunc ? props.nameFunc(option) : option.name)}
        onChange={handleChange}
        sx={props.style}
        renderInput={(params) => <TextField style={props.style} {...params} label={props.title} />}
    />
}