import React from 'react';
import { getAPIData, getAPIDataReturn } from '../utils/apiFunction';
import Autocomplete from '@mui/material/Autocomplete';
import SelectTextField from './SelectTextField';
import TextField from "@mui/material/TextField";
import { supplierToMatch } from '../utils/constants/warehouse'


const SelectAOCFarmWarehouse = (props) => {
    const [warehouse, setWarehouse] = React.useState([]);
    const [comboList, setComboList] = React.useState([]);
    const [value, setValue] = React.useState(props.state);
    const [cache, setCache] = React.useState(null);
    const [inputValue, setInputValue] = React.useState('');
    const [aocFarms, setAOCFarms] = React.useState([]);

    React.useEffect(() => {
        if (props.warehouseId && comboList) {
            var est = comboList.find((dr) => { return (dr.warehouseId === props.warehouseId) });
            setValue(est);
        }
    }, [comboList, props.warehouseId])
    React.useEffect(() => {
        if (!props.notFirstOne) {
            if (!props.warehouseId) {
                setValue(comboList[0]);
            }
        }
    }, [comboList])

    React.useEffect(() => {
        getAOCWarehouses(props.access, props.showAll, setComboList,props.sortToTop);
    }, []);
    return (
        <SelectTextField style={props.normal ? {} : { minWidth: '250px' }} checkID={value?.id} title={props.title ? props.title : "AOC Farm"} state={value} setState={(val) => { setValue(val); props.setState(val); }} list={comboList} nameFunc={(op) => { return op.name }} disabled={props.disabled} />
    );



}
export default SelectAOCFarmWarehouse;

export const getAOCWarehouses = async (access, showAll, setFunc, sortToTop) => {
    var promArr = [];
    promArr.push(getAPIDataReturn('/farmerssuppliers'));
    promArr.push(getAPIDataReturn('/Warehouses/Get'));
    var res = await Promise.all(promArr);
    var inp = res[0];
    var warehouse = res[1].sort((a, b) => {
        return a.description > b.description ? 1 : -1;
    });
    var aocFarms = inp.farmers?.filter((f) => { return f.isInternal });

    var newCombo = []
    if (!showAll) {
        if ((aocFarms.length > 0) && (warehouse.length > 0)) {
            for (var i = 0; i < aocFarms.length; i++) {
                var matchObj = supplierToMatch(aocFarms[i].name);
                if (access?.find((a) => { return a.key === matchObj.access })) {
                    newCombo.push(aocFarms[i])
                }
            }
        }
    } else {
        newCombo = (aocFarms);
        if (sortToTop) {
            newCombo = newCombo.sort((a, b) => {
                return access?.find((acc) => { return acc.key === supplierToMatch(a.name).access }) ? -1 : 1;
            });

        }
    }
    setFunc(newCombo)
}