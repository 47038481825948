import React from 'react';
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import { CircularProgress } from '@mui/material';
import GetAppIcon from '@mui/icons-material/GetApp';


export const DownloadButton = (props) => {

    const download = () => {


        var tables = convertToCSV(props.data)

        return encodeURI("data:text/csv;charset=utf-8," + tables);
    }
    return <React.Fragment>
        {!props.start?<Button variant={'contained'} style={props.style?props.style:{marginTop:'10px'}} onClick={()=>(props.setStart(true))}>Download</Button>:
        (props.data ? <IconButton href={download()} style={props.style?props.style:{}} download={'Report_' + new Date().toLocaleString() + '.csv'} >{props.useText ? <Button variant={'contained'} >Download</Button>:<GetAppIcon />}</IconButton> : <CircularProgress />)}
    </React.Fragment>

}


export function convertToCSV(arr) {
    if (arr.length > 0) {
        const array = [Object.keys(arr[0])].concat(arr);
        return array.map(it => {
            return Object.values(it).toString()
        }).join('\n')
    }
}
export function convertArrayToCSV(arr) {
    if (arr.length > 0) {
        return arr.map(it => {
            return Object.values(it).toString()
        }).join('\n')
    }
}

