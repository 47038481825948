import jsPDF from 'jspdf';
import title from '../images/PackingSlipPOD-Title.PNG';
import Haccp from '../images/Haccp.png';
import { getAPIData, getAPIDataParamsReturn, getAPIDataReturn, handlePagination } from './apiFunction';
import { dateFormat, textfieldDateFormat, tomorrow } from './dateFunctions';
import { numberFormat } from './numberFunctions';
import { MYOBTOREAD } from './constants/warehouse';

const downloadPackingSlip = async (soId, startDate, warehouse) => {
    const uri = process.env.REACT_APP_BASE_URL;

    const ifUndef = (field) => {
        return field ? field : '';
    }
    //e.stopPropagation();
    var salesOrders = [];
    var shipments = [];
    var customers = [];
    if (soId) {
        salesOrders = await getAPIDataParamsReturn('/SalesOrder/Get/' + soId, { SalesOrderId: soId });
        customers = [await getAPIDataParamsReturn('/Customers/GetById', { CustomerId: salesOrders[0].customerId })];
    } else {
        salesOrders = await handlePagination(fetch, uri + ('/SalesOrder/Get'), textfieldDateFormat(startDate), textfieldDateFormat((startDate)));
        if (warehouse) {
            var warehouses = await getAPIDataReturn('/Warehouses/Get');
            var warehouseId = warehouses.find((wh) => (wh.myobid === warehouse))?.id;
            console.log(warehouseId, salesOrders);
            salesOrders = salesOrders.filter((so) => {
                var found = false;
                for (var i = 0; i < so.salesOrderLines.length; i++) {
                    //console.log(so.salesOrderLines[i], so.salesOrderLines[i].warehouseId, warehouseId);
                    if (so.salesOrderLines[i].warehouseId === warehouseId) {
                        if (so.salesOrderLines[i].quantity > 0) {
                            found = true;
                            break;
                        }
                    }
                }
                return found;
            }
            );
            console.log(warehouseId, salesOrders.length);
        }
        customers = await getAPIDataReturn('/Customers/Get');
    }
    shipments = await handlePagination(fetch, uri + ('/Shipment/Get'), textfieldDateFormat(startDate), textfieldDateFormat(tomorrow(tomorrow(tomorrow(startDate)))));
    //var stockItems = await getAPIDataReturn('/StockItems/Get');
    var doc = new jsPDF({
        orientation: "portrait",
        unit: "mm",
        format: [210, 297]
    });
    //if bigger than 18 addPage
    salesOrders = salesOrders.filter((so) => (so.status !== 'Canceled') && (so.status !== 'Cancelled'));
    var fontothertitle = 10;
    var fontbig = 15;
    var fontbiggerText = 9;
    var fontText = 7;

    var titleI = new Image();
    titleI.src = title;
    var haccpI = new Image();
    haccpI.src = Haccp;
    for (var j = 0; j < salesOrders.length; j++) {
        var customer = customers.find((cs) => (cs.id === salesOrders[j].customerId));
        var relevantShips = shipments.filter((s) => ((s.salesOrderId === salesOrders[j].id) && ((s.status === 'Completed') || (s.status === 'Confirmed') || (s.status === 'Invoiced') || (s.status === 'Partially Invoiced'))));
        var openShips = shipments.filter((s) => ((s.salesOrderId === salesOrders[j].id) && ((s.status !== 'Completed') && (s.status !== 'Confirmed') && (s.status !== 'Invoiced') && (s.status !== 'Partially Invoiced'))));
        var salesOrderLineList = salesOrders[j].salesOrderLines?.map((a) => ({ ...a }));
        var shipmentId = '';
        var orderId = String(salesOrders[j]?.myobid);
        var customerPO = String(salesOrders[j]?.poNumber) || '';
        var prnArr = [];
        var orderList = [];
        var lineWidthTight = 5;
        var lineWidth = 6
        var max = 18;
        var totalPages = Math.ceil(salesOrders[j].salesOrderLines.length / max);
        var rightJusty = 125;
        var rightJustyEnd = 200;
        var line = 0;
        if (relevantShips.length > 0) {
            for (var k = 0; k < relevantShips.length; k++) {
                for (var l = 0; l < relevantShips[k].shipmentLines.length; l++) {
                    if (relevantShips[k].shipmentLines[l].shippedQty > 0) {
                        prnArr.push({ ...relevantShips[k].shipmentLines[l], quantity: relevantShips[k].shipmentLines[l].shippedQty });
                    }
                    var soIndex = salesOrderLineList.findIndex((a) => ((a.stockItemId === relevantShips[k].shipmentLines[l].stockItemId) && (a.warehouseId === relevantShips[k].shipmentLines[l].warehouseId)));
                    if (soIndex >= 0) { // find all
                        salesOrderLineList.splice(soIndex, 1);
                    }
                }
            }
        }
        if (salesOrderLineList.length > 0) {
            for (var k = 0; k < salesOrderLineList.length; k++) {
                if ((!relevantShips.find((ship) => (ship.wareHouseId === salesOrderLineList[k].warehouseId))) || (openShips.find((ship) => (ship.wareHouseId === salesOrderLineList[k].warehouseId)))) {
                    if (salesOrderLineList[k].quantity > 0) {
                        prnArr.push({ ...salesOrderLineList[k] });
                    }
                }
            }
        }
        // if shipments check if that warehouse/item is in the shipment and use shipment total if not in shipment do not show
        // if no shipment for that warehouse include all sale order lines for that warehouse
        if (j !== 0) {
            doc.addPage();
        }
        for (var i = 0; i < totalPages; i++) {
            if (i !== 0) {
                doc.addPage();
            }
            line = 0;
            doc.addImage(titleI, 'PNG', 0, line, 210, 56.44);
            line += 60;
            doc.setFontSize(fontbig);
            doc.text('Invoice To: ', 10, line);

            //doc.text('Packing Slip Ref: ', rightJusty, line);
            doc.text(shipmentId ? shipmentId : '', rightJustyEnd, line, { align: 'right' });
            line += lineWidth;
            var tempLine = line;
            doc.setFontSize(fontbiggerText);
            doc.text(ifUndef(customer?.name), 10, line);
            line += lineWidthTight;
            doc.text(ifUndef(customer?.billingAddress?.line1), 10, line);
            line += lineWidthTight;
            doc.text(ifUndef(customer?.billingAddress?.line2), 10, line);
            line += lineWidthTight;
            doc.text(ifUndef((customer?.billingAddress?.city + ' ' + customer?.billingAddress?.state + ' ' + customer?.billingAddress?.postcode)), 10, line);
            line += lineWidthTight;



            doc.setFontSize(fontText);
            line = tempLine;
            doc.text('Date:', rightJusty, line);
            doc.text(dateFormat(new Date()), rightJustyEnd, line, { align: 'right' });

            line += lineWidth;
            doc.text('Customer ID:', rightJusty, line);
            doc.text(customer.myobid, rightJustyEnd, line, { align: 'right' });
            line = 90;
            doc.setLineWidth(0.3)
            doc.rect(5, line, 200, 12);
            line += lineWidthTight;
            doc.setFontSize(fontothertitle);
            doc.text('SO NUMBER', 20, line, { align: 'center' });
            doc.text('CUST PO', 55, line, { align: 'center' });
            doc.text('SHIP VIA', 90, line, { align: 'center' });
            doc.text('CONTACT', 130, line, { align: 'center' });
            //doc.text('WAREHOUSE', 185, line, { align: 'center' });

            line += lineWidthTight;

            doc.setFontSize(fontText);
            doc.text(orderId, 20, line, { align: 'center' });
            doc.text(customerPO, 55, line, { align: 'center' });
            doc.text(customer.shipVia ? customer.shipVia : 'AOC2', 90, line, { align: 'center' });
            doc.text('1300 262 697', 130, line, { align: 'center' });
            //doc.text(MYOBTOREAD[orderList[0].location] ? MYOBTOREAD[orderList[0].location] : orderList[0].location, 185, line, { align: 'center' });

            doc.setFontSize(fontothertitle);
            line += lineWidth + 2;
            doc.text('DESCRIPTION', 10, line);
            //doc.text('ESTUARY-PRN', 130, line, { align: 'center' });
            doc.text('QTY.', 175, line, { align: 'right' });
            doc.text('UOM', 200, line, { align: 'right' });
            doc.setFontSize(fontText);
            var totalQuantity = 0;
            for (var x = max * i; ((x < (max * (i + 1))) && (x < prnArr.length)); x++) {
                var currSI = prnArr[x].stockItem;
                totalQuantity += prnArr[x].quantity;
                //loop
                line += lineWidth;
                doc.text(ifUndef(currSI?.description), 10, line);
                //doc.text(prnArr[x].lotSerialNbr ? prnArr[x].lotSerialNbr : '', 130, line, { align: 'center' });
                doc.text(String(prnArr[x].quantity), 175, line, { align: 'right' });
                doc.text((currSI?.isProcessed ? 'DOZEN' : 'UNITS'), 200, line, { align: 'right' });
            }


            doc.setFontSize(11);

            doc.setLineWidth(0.3)
            doc.text('Receiver Name', 10, 227);
            doc.line(47, 227, 115, 227);
            doc.text('Temp.', 120, 227);
            doc.line(133, 227, 155, 227);

            doc.text('Receiver Signature', 10, 236);
            doc.line(47, 236, 115, 236);
            doc.text('Time', 120, 236);
            doc.line(133, 236, 155, 236);

            doc.setLineWidth(0.9)
            doc.rect(10, 240, 95, 50);
            doc.setFontSize(fontbig);
            line = 247;
            doc.text('SHIP TO:', 12, line);
            doc.text('Total Quantity: ' + numberFormat(totalQuantity) + ' Doz', 130, line);
            line += lineWidth + 1;

            doc.setFontSize(fontbig - 4);
            doc.text(ifUndef(customer?.name), 12, line);
            line += lineWidthTight;
            doc.text(ifUndef(customer?.shippingAddress?.line1), 12, line);
            line += lineWidthTight;
            doc.text(ifUndef(customer?.shippingAddress?.line2), 12, line);
            line += lineWidthTight;
            doc.text(ifUndef(ifUndef(customer?.shippingAddress?.city) + ' ' + ifUndef(customer?.shippingAddress?.state) + ' ' + ifUndef(customer?.shippingAddress?.postcode)), 12, line);




            doc.addImage(haccpI, 'PNG', 185, 270, 20, 20);
            doc.setFontSize(fontText);
            doc.text('Page ' + (i + 1) + ' of ' + totalPages, 193, 293);
        }
    }






    doc.save('ProofOfDelivery-' + orderId + '-' + new Date().toLocaleString() + '.pdf');
}


export default downloadPackingSlip