import React from 'react';
import Grid from "@mui/material/Grid";
import { startOfWeekMon, endOfWeekSun, tomorrow } from "../../utils/dateFunctions";
import DateSelector from "../../shared/DateSelector";
import QualityAssurance from './Tables/QualityAssurance';
const QualityAssurancePage = (props) => {
    const [startDate, setStartDate] = React.useState(startOfWeekMon(new Date()));
    const [endDate, setEndDate] = React.useState(endOfWeekSun(new Date()));
    return (
        <React.Fragment>
            <Grid container spacing={2} mb={1}>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={3}>
                    <DateSelector montosun startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate} />
                </Grid>
            </Grid>
            <Grid container spacing={3.5}>
                <Grid item xs={12}>
                    <QualityAssurance startDate={startDate} endDate={tomorrow(endDate)} access={props.access} />
                </Grid>
        </Grid>
        </React.Fragment >
    );
};


export default QualityAssurancePage;
