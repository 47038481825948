import React from 'react';
import { getOysterGrades, gradeSort } from "../../../definitions/oysters";
import MUIDataTable from "mui-datatables";
import { CircularProgress, Icon } from "@mui/material";
import ToggleButton from '@mui/material/ToggleButton';
import IconButton from '@mui/material/IconButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { tableTheme } from '../../../themes/tableTheme';
import { TableCell, TableRow, Table, TableHead, Typography, TableContainer } from "@mui/material";
import { adalApiFetch } from '../../../../adalConfig';
import DataTableStandard from "../../../components/DataTableStandard";
import DayToggle from "../../../components/DayToggle";
import { headerRender } from '../../../utils/renderFunctions';
import TooltipDescription from "../../../components/TooltipDescription";
import { numberFormat } from '../../../utils/numberFunctions';
import { textfieldDateFormat, DayDateMon, generateDates, dateToZero, dateFormat, tomorrow, yesterday, shiftHours } from '../../../utils/dateFunctions';
import { useJumboTheme } from "@jumbo/hooks";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import { getAPIDataParamsReturn, getAPIDataParams } from '../../../utils/apiFunction';
import { Expand } from '@mui/icons-material';
import CompressIcon from '@mui/icons-material/Compress';
const VARIATIONS = 'Variations';
const Detail = (props) => {
    const { theme } = useJumboTheme();
    var bgCol = theme.palette.oyster.AN;
    const trafficLight = [{ color: theme.palette.trafficLight.red, status: 'Calculated' }, { color: theme.palette.trafficLight.orange, status: 'Not all manual inventories have been added' }, { color: theme.palette.trafficLight.green, status: 'Manually Entered Inventory' }]
    const uri = process.env.REACT_APP_BASE_URL;
    const [procurement, setProcurement] = React.useState([]);
    const [prevWeekData, setPrevWeekData] = React.useState([]);
    const [varShowArray, setVarShowArray] = React.useState([]);
    const [showTitleAdd, setShowTitleAdd] = React.useState([]);
    const [allDates, setAllDates] = React.useState([]);
    const [nonZeroGrades, setNonZeroGrades] = React.useState([]);
    const [reload, setReload] = React.useState(true);
    const reloadFunc = () => {
        setReload(!reload);
    }
    React.useEffect(() => {

    }, []);
    React.useEffect(() => {
        const abortController = new AbortController();
        setProcurement([]);
        getOysterGrades().then((data) => {
            getProcurementData(props.location, textfieldDateFormat(props.startDate), textfieldDateFormat(props.endDate), gradeSort(data), abortController);
        });
        return () => {
            abortController.abort();
        };
    }, [props.location, props.startDate, props.endDate, reload, props.externalReload]);

    var getProcurementData = async (location, startDate, endDate, grades, abortController) => {
        var allDats = generateDates(dateToZero(startDate), (dateToZero(endDate)));
        allDats.push(dateToZero(endDate));
        setAllDates(allDats);
        setVarShowArray(new Array(allDats.length).fill(false));
        var params = '';
        var prmArr = [];
        // send a request for each day in the range
        //for (var i = 0; i < allDats.length; i++) {
        //    params = new URLSearchParams({
        //        Location: location,
        //        StartDate: textfieldDateFormat(allDats[i])
        //    })
        //    prmArr.push(getAPIDataParamsReturn('/ProcurementDashboard/DailyProcurement', params));
        //}
        //var data = await Promise.all(prmArr);
        params = new URLSearchParams({
            Location: location,
            StartDate: textfieldDateFormat(allDats[0]),
            EndDate: textfieldDateFormat(allDats[allDats.length - 1])
        })
        var data = await getAPIDataParamsReturn('/ProcurementDashboard2/DailyProcurement', params, abortController)
        setProcurement(data);
        var nZGrades = [];
        for (var j = 0; j < grades.length; j++) {
            var grade = grades[j];
            var hasData = false;
            for (var i = 0; i < data.length; i++) {
                var day = data[i];
                //loop through each key in day
                for (var key in day) {
                    if (!isNaN(day[key]?.[grade.abv.toLowerCase()]) && (day[key]?.[grade.abv.toLowerCase()] !== 0)) {
                        hasData = true
                    }
                }
                if (!isNaN(day[grade.abv.toLowerCase()]) && (day[key]?.[grade.abv.toLowerCase()] !== 0)) {
                    hasData = true
                }
            }
            if (hasData) {
                nZGrades.push(grade);
            }
        }
        setNonZeroGrades(nZGrades);
        //params = new URLSearchParams({
        //    Location: location,
        //    StartDate: textfieldDateFormat(yesterday(allDats[0]))
        //})
        //getAPIDataParams('/ProcurementDashboard/DailyProcurement', setPrevWeekData, params);


    }


    var titleList = ['Procured for SO today','Transfers In', VARIATIONS, 'Live Variations', 'Halfshell Variations', 'Fulfilment Variations', 'Back To Water', 'Frozen', 'Manual Adjustments', 'Cycle Count', 'Sales Orders',  'Transfers Out', 'Expected Over/(Under) position', 'Manual Inventory', 'Procured for SO next day', 'Closing SOH'];
    const tooltitle = <div>
        <div>This table shows the oyster sales and procurement for the selected day/s. If the day is current or in the future it is the expected values, if it is in the past it is the actual values completed on that day.</div>
        <br />
        <div>
            <span style={{ backgroundColor: theme.palette.trafficLight.red }}>Red</span> indicates both inventories havent been added
        </div>
        <div>
            <span style={{ backgroundColor: theme.palette.trafficLight.orange }}>Orange</span> indicates one inventory has been added
        </div>
        <div><span style={{ backgroundColor: theme.palette.trafficLight.green }}>Green</span> indicates both inventories have been added
        </div>
    </div>
    var cellStyle = {
        padding: '4px',
        maxWidth: '100px',
        color: 'black'
    }
    const sumAllLines = (lines) => {
        var total = 0;
        var lineData = {};
        for (var i = 0; i < lines.length; i++) {
            var line = lines[i];
            for (var key in line) {
                if (key !== 'total') {
                    if (!lineData[key]) {
                        lineData[key] = 0;
                    }
                    lineData[key] = lineData[key] + line[key];
                }
            }
            total = total + line.total;
        }
        lineData.total = total;
        return lineData;
    }

    const matchData = (p, title) => {
        var lineData = {};
        var isHidden = false;
        switch (title) {
            case 'Sales Orders':
                lineData = p.salesOrders;
                break;
            case 'Procured for SO today':
                lineData = p.purchaseOrdersToday;
                break;
            case 'Live Variations':
                lineData = p.liveVariations;
                isHidden = true;
                break;
            case 'Halfshell Variations':
                lineData = p.halfshellVariations;
                isHidden = true;
                break;
            case 'Fulfilment Variations':
                lineData = p.fulfilmentVariations;
                isHidden = true;
                break;
            case 'Back To Water':
                lineData = p.backToWater;
                isHidden = true;
                break;
            case 'Frozen':
                lineData = p.frozen;
                isHidden = true;
                break;
            case 'Manual Adjustments':
                lineData = p.manualAdjustments;
                isHidden = true;
                break;
            case 'Cycle Count':
                lineData = p.cycleCount;
                isHidden = true;
                break;
            case VARIATIONS:
                lineData = sumAllLines([p.liveVariations, p.halfshellVariations, p.fulfilmentVariations, p.backToWater, p.frozen, p.manualAdjustments, p.cycleCount]);
                break;
            case 'Manual Adjustment':
                lineData = p.manualAdjustments;
                break;
            case 'Expected Over/(Under) position':
                lineData = p.expectedPosition;
                break;
            case 'Procured for SO next day':
                lineData = p.purchaseOrdersNextDay;
                break;
            case 'Manual Inventory':
                lineData = p.inventory;
                break;
            case 'Transfers In':
                lineData = p.transfersIn;
                break;
            case 'Transfers Out':
                lineData = p.transfersOut;
                break;
            case 'Closing SOH':
                lineData = p.closing;
                break;
            default:
                break;
        }
        return [lineData, isHidden];
    }
    const getColOy = (OyAbv) => {
        var col = 'lightgrey';
        if (OyAbv.includes('SR')) {
            col = theme.palette.oyster.SR;
        } else if (OyAbv.includes('PC')) {
            col = theme.palette.oyster.PC;
        } else if (OyAbv.includes('AN')) {
            col = theme.palette.oyster.AN;
        }
        return col;
    }
    const borderfunction = (showAllVars,displayTitle) => {
        if(showAllVars){
            if(displayTitle == VARIATIONS){
                return {borderBottom:'1px solid black'};
            }else if(displayTitle == 'Sales Shipments'){
                // return {borderTop:'3px solid black'};
            }
        }
    }
    return (
        <JumboCardQuick
            wrapperSx={{
                p: 3,
                pt: 0,
                backgroundColor: 'background.paper',
                zIndex: 0,
                '&:last-child': {
                    pb: 1
                }
            }}
            title={<Typography variant={'h2'} sx={{ paddingBottom: 0 }} >Daily Procurement</Typography>}
        >
            {procurement.length > 0 ? <TableContainer>
                {/*style={{ overflowX: "initial" }} */}
                <Table >
                    {/*// stickyHeader style={{  borderCollapse: 'collapse'  }}*/}
                    <TableHead >
                        <TableRow >
                            <TableCell sx={{ pl: 3 }}>
                            </TableCell>
                            <TableCell sx={{ pl: 3 }}>
                            </TableCell>
                            {nonZeroGrades.map((og) => {

                                return <TableCell sx={{ textAlign: 'right', fontSize: 'medium', ...cellStyle, background: getColOy(og.abv), fontWeight: 600 }}>
                                    {og.abv}
                                </TableCell>
                            })}

                            <TableCell sx={{ pl: 3, textAlign: 'right', background: getColOy('total'), fontWeight: '600', ...cellStyle, fontSize: 'medium' }}>
                                Total
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableRow hover style={{ zIndex: 1000, borderTop: '2.5px solid black', borderBottom: '2.5px solid black' }} >
                        <TableCell sx={cellStyle} >
                        </TableCell>
                        <TableCell sx={cellStyle}>
                            <Typography style={{ fontWeight: '600' }} variant={"h6"} mb={0}>Opening SOH</Typography>
                        </TableCell>
                        {nonZeroGrades.map((og) => {
                            return <TableCell sx={{ textAlign: 'right', ...cellStyle, fontWeight: '600', background: getColOy(og.abv) }}>
                                {!isNaN((procurement[0].opening?.[og.abv.toLowerCase()])) ? numberFormat(procurement[0].opening?.[og.abv.toLowerCase()]) : 0}
                            </TableCell>
                        })}
                        <TableCell sx={{ textAlign: 'right', ...cellStyle, fontWeight: '600', background: getColOy('total') }}>
                            {!isNaN((procurement[0]?.opening?.total)) ? numberFormat(procurement[0]?.opening?.total) : 0}
                        </TableCell>
                    </TableRow>
                    {procurement.map((p, dateInd) => {
                        var showTransIn = false;
                        var showTransOut = false;
                        var showInventory = false;
                        var showVariations = false;
                        var transInMO = matchData(p, 'Transfers In');
                        var transOutMO = matchData(p, 'Transfers Out');
                        var inventoryMO = matchData(p, 'Manual Inventory');
                        var variationsMO = matchData(p, VARIATIONS);
                        var transIn = transInMO[0];
                        var transOut = transOutMO[0];
                        var inventory = inventoryMO[0];
                        var variations = variationsMO[0];

                        var showAllVars = varShowArray[dateInd];
                        console.log(showAllVars);
                        nonZeroGrades.map((og) => {
                            if (!isNaN(transIn?.[og.abv.toLowerCase()]) && transIn?.[og.abv.toLowerCase()] !== 0) {
                                showTransIn = true;
                            }
                        });
                        nonZeroGrades.map((og) => {
                            if (!isNaN(transOut?.[og.abv.toLowerCase()]) && transOut?.[og.abv.toLowerCase()] !== 0) {
                                showTransOut = true;
                            }
                        });
                        nonZeroGrades.map((og) => {
                            if (!isNaN(inventory?.[og.abv.toLowerCase()]) && inventory?.[og.abv.toLowerCase()] !== 0) {
                                showInventory = true;
                            }
                        });
                        nonZeroGrades.map((og) => {
                            if (!isNaN(variations?.[og.abv.toLowerCase()]) && variations?.[og.abv.toLowerCase()] !== 0) {
                                showVariations = true;
                            }
                        });

                        var rowsTotal = titleList.length;
                        if (!showTransIn) {
                            rowsTotal = rowsTotal - 1;
                        }
                        if (!showTransOut) {
                            rowsTotal = rowsTotal - 1;
                        }
                        if (!showInventory) {
                            rowsTotal = rowsTotal - 1;
                        }
                        if (!showVariations) {
                            rowsTotal = rowsTotal - 1;
                        }
                        if (!showAllVars) {
                            rowsTotal = rowsTotal - 7;
                        } else {
                            rowsTotal = rowsTotal ;
                        }

                        return titleList.map((title, ind) => {
                            var extraStyle = {};
                            var extraCellStyle = {};

                            if (title === 'Closing SOH') {
                                extraStyle = {
                                    borderTop: '2.5px solid black',
                                    borderBottom: '2.5px solid black',
                                    fontWeight: '600'
                                }
                                extraCellStyle = {
                                    fontWeight: '600'
                                }
                            } else if (title === 'Expected Over/(Under) position') {
                                extraCellStyle = {
                                    fontWeight: '600'
                                }
                                extraStyle = {
                                    borderBottom: '2.5px dotted black',
                                }
                            }
                            var allInv = true;
                            var show = true;
                            var matchOut = matchData(p, title);
                            var lineData = matchOut[0];
                            var isHidden = matchOut[1];

                            var highlightInvCol = {};
                            if (title === 'Transfers In') {
                                show = false;
                                if (showTransIn) {
                                    show = true;
                                }
                            } else if (title === 'Transfers Out') {
                                show = false;
                                if (showTransOut) {
                                    show = true;
                                }
                            } else if (title === 'Manual Inventory') {
                                show = false;
                                if (showInventory) {
                                    show = true;
                                }
                                if ((p.cranbrookInv !== p.sydneyInv) && (props.location === 0)) {
                                    highlightInvCol = {
                                        background: 'rgba(255, 181, 76,0.5)'
                                    }
                                    allInv = false;
                                }
                            }
                            var displayTitle = title;
                            if (title === 'Closing SOH') {
                                displayTitle = allDates?.[dateInd]?.toLocaleString('en-us', { weekday: 'long' }) + ' ' + title;
                            }
                            if (title === 'Sales Orders') {
                                displayTitle = shiftHours(new Date(allDates?.[dateInd]), 16) > new Date() ? 'Sales Orders' : 'Sales Shipments';
                            }
                            if ((title === 'Manual Inventory') && !allInv) {
                                displayTitle = (p.cranbrookInv ? 'Cranbrook' : 'Sydney') + ' Stocktake only';
                            }
                            if (isHidden) {
                                if (!showAllVars) {
                                    show = false;
                                }
                            }
                            if ((title === VARIATIONS)) {
                                if (!showVariations) {
                                    show = false;
                                }
                            }
                            if (show) {
                                return <TableRow hover style={{ zIndex: 1000, ...extraStyle, }} >
                                    {ind === 0 ? <TableCell sx={cellStyle} rowSpan={rowsTotal} >
                                        <Typography sx={{ fontWeight: 'bold', textAlign: 'center' }} variant={"h6"} mb={0}>{DayDateMon(allDates[dateInd])}</Typography>
                                    </TableCell> : null}
                                    {/*//if transfers*/}
                                    <TableCell sx={{ ...cellStyle, ...highlightInvCol ,...(borderfunction(showAllVars,displayTitle))}}>
                                        {/* expan icon button */}

                                        <Typography style={{...extraCellStyle,...(isHidden?{marginLeft:'20px'}:{})}} variant={"h6"} mb={0}>{isHidden?'- ':''}{displayTitle}{displayTitle == VARIATIONS ? (<IconButton style={{ height: '20px', paddingTop: 0, paddingBottom: 0 }} onClick={() => { var cpvsa = { ...varShowArray }; cpvsa[dateInd] = !cpvsa[dateInd]; setVarShowArray(cpvsa); }}>
                                            {showAllVars?<CompressIcon/>:<Expand />}
                                        </IconButton>) : null}</Typography>
                                    </TableCell>
                                    {nonZeroGrades.map((og) => {
                                        var cellExtraCol = {};
                                        if (title === 'Expected Over/(Under) position') {
                                            if (lineData?.[og.abv.toLowerCase()] < 0) {
                                                if (shiftHours(new Date(allDates?.[dateInd]), 20) > new Date()) {
                                                    cellExtraCol = {
                                                        background: 'rgba(255, 105, 97, 0.5)',
                                                    }
                                                }
                                            }
                                        }
                                        return <TableCell sx={{ textAlign: 'right', ...cellStyle, ...extraCellStyle, background: getColOy(og.abv), ...cellExtraCol, ...highlightInvCol }}>
                                            {lineData?.[og.abv.toLowerCase()] ? numberFormat(lineData?.[og.abv.toLowerCase()]) : 0}
                                        </TableCell>
                                    })}
                                    <TableCell sx={{ textAlign: 'right', ...cellStyle, ...extraCellStyle, background: getColOy('total'), ...highlightInvCol }}>
                                        {lineData?.total ? numberFormat(lineData?.total) : 0}
                                    </TableCell>
                                </TableRow>
                            } else {
                                return null;
                            }
                        });
                    })}
                </Table>
            </TableContainer> : <CircularProgress />}
        </JumboCardQuick>
    );
};

export default Detail;
