export const WAREHOUSEGROUPS = {
    Sydney: 'Syd',
    BBay: 'BBay',
};

export const WAREHOUSESTRINGS = {
    Sydney: 'SYDNEY',
    BBayLive: 'LIVEPRODCT',
    BBayHalfshell: 'HALFSHELL',
};
export const KEYWAREHOUSESTRINGS = [
    'SYDNEY','LIVEPRODCT'
];
export const WAREHOUSE_ACCESS = {
    BERMAGUI: 'Bermagui',
    HASTINGS: 'Hastings',
    MERIMBULA: 'Merimbula',
    NAROOMA: 'Narooma',
    SHOALHAVEN: 'Shoalhaven',
    WALLIS: 'Wallis',
    WAPENGO: 'Wapengo',
    HATCHERY: 'Hatchery'
};

export const WAREHOUSEMATCHES = {
    'Syd': ['SYDNEY'],
    'BBay': ['LIVEPRODCT', 'HALFSHELL'],
    'Contractor': ['CONTRACTOR'],
};
export const MYOBTOREAD = {
    'SYDNEY': 'SYDNEY',
    'LIVEPRODCT': 'LIVE PRODUCT',
    'HALFSHELL': 'HALF SHELL',
    'CONTRACTOR': 'CONTRACTOR',
};

export const WAREHOUSE = {
    BERMAGUI: 'BERMAGUI',
    HASTINGS: 'HASTINGS__',
    MERIMBULA: 'MERIMBULA',
    NAROOMA: 'NAROOMA___',
    SHOALHAVEN: 'SHOALHAVEN',
    WALLIS: 'WALLIS',
    WAPENGO: 'WAPENGO',
    HATCHERY: 'HATCHERY'
};


export const SUPPLIER = {
    BERMAGUI: "AOC Bermagui Farm (Internal)",
    HASTINGS: "AOC Hastings Farm (Internal)",
    MERIMBULA: "AOC Merimbula Farm (Internal)",
    NAROOMA: "AOC Narooma Wagonga Farm (Internal)",
    SHOALHAVEN: "AOC Shoalhaven Farm (Internal)",
    WALLIS: "AOC Wallis Lake Farm (Internal)",
    WAPENGO: "AOC Wapengo Farm (Internal)",
    HATCHERY: "AOC External Hatchery",
}


export const WarehouseMatchingObj = () => {
    var obj = {};
    Object.keys(WAREHOUSE).map((key) => {
        obj[key] = {
            access: WAREHOUSE_ACCESS[key],
            warehouse: WAREHOUSE[key],
            supplier: SUPPLIER[key],

        }
    })
    return obj;
}

export const warehouseToMatch = (warehouse) => {
    for (var i = 0; i < Object.keys(WAREHOUSE).length; i++) {
        if (WAREHOUSE[Object.keys(WAREHOUSE)[i]] === warehouse) {
            return WarehouseMatchingObj()[Object.keys(WAREHOUSE)[i]];
        }
    }
    return {};
}
export const supplierToMatch = (supplier) => {
    for (var i = 0; i < Object.keys(SUPPLIER).length; i++) {
        if (SUPPLIER[Object.keys(SUPPLIER)[i]] === supplier) {
            return WarehouseMatchingObj()[Object.keys(SUPPLIER)[i]];
        }
    }
    return {};
}
