import React from 'react';
import { dateFormat, textfieldDateFormat, shiftHours } from "../../../utils/dateFunctions";
import { getAPIData, getAPIDataReturn, handlePagination } from "../../../utils/apiFunction";
import { getOysterGrades, gradeSort } from "../../../definitions/oysters";
import { CircularProgress, IconButton, Typography } from "@mui/material";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import DataTableStandard from "../../../components/DataTableStandard";
import { useJumboTheme } from "@jumbo/hooks";
import { TableCell, TableRow, TableBody, Table, TableHead } from "@mui/material";
import { Link } from "@mui/material";

const SalesOrders = (props) => {
    const uri = process.env.REACT_APP_BASE_URL;
    const [loading, setLoading] = React.useState(false);
    const [columns, setColumns] = React.useState([]);
    const [procurement, setProcurement] = React.useState([]);
    const [showCompleted, setShowCompleted] = React.useState(false);
    const [grades, setGrades] = React.useState([]);
    const { theme } = useJumboTheme();
    const quantityLabel = 'Total Oyster Quantity';
    React.useEffect(() => {
        getOysterGrades().then((data) => {
            setGrades(gradeSort(data));
        });
    }, []);

    React.useEffect(() => {
        var cols = [


            {
                name: 'name',
                label: 'Customer',
                options: props.customerFilter ? { filterList: [props.customerFilter] } : {}
            },

            {
                name: 'status',
                label: 'Status'
            },
            {
                name: 'startDate',
                label: 'Start Date'
            },
            {
                name: 'endDate',
                label: 'End Date'
            },
            {
                name: 'frequency',
                label: 'Frequency (weeks)'
            },
            {
                name: 'days',
                label: 'Days'
            },
            {
                name: 'description',
                label: 'Description'
            },
            //{
            //    name: 'quantity',
            //    label: quantityLabel
            //}, This is not showing the shipped quantity
            ...oysterCols,
            {
                name: 'nonStockQuantity',
                label: 'Non Stock Quantity'
            },
            {
                name: 'Duplicate',
                label: 'Duplicate',
                options: {
                    customBodyRenderLite: (dataIndex) => {
                        var tenant = process.env.REACT_APP_MYOB_TENANT ? process.env.REACT_APP_MYOB_TENANT : 'AOCLive';
                        var proc = props.filter ? props.filter(procurement) : procurement;
                        return <IconButton style={{ height: '20px' }} onClick={(e) => {
                            e.stopPropagation();
                            props.duplicateOrder(proc[dataIndex]);
                        }} ><ContentCopyIcon /></IconButton>
                    }
                }
            }

        ]

        setColumns(cols);
    }, [props.customerFilter, procurement, grades])
    var oysterCols = grades.map((og) => {
        return {
            name: String(og.id),
            label: og.abv,
            options: {
                filter: false
            }
        }
    })

    const options = {
        filterType: 'dropdown',
        selectableRowsHideCheckboxes: true,
        fixedHeader: true,
        rowsPerPage: 1000,
        responsive: 'standard',
        onRowClick: props.onClick ? props.onClick(procurement) : (() => { }),
        setRowProps: row => {
            var selRow = row[1];
            if (selRow === ("Closed")) {
                return {
                    style: { background: theme.palette.oysterProducts.completed }
                };
            } else if (selRow === ("Ending Soon")) {
                return {
                    style: { background: 'orange' }
                };
            } else if (selRow === ("Active")) {
                return {
                    style: { background: theme.palette.oysterProducts.open }
                };
            } else if (selRow === ("Not Started")) {
                return {
                    style: { background: theme.palette.oysterProducts.onhold }
                };
            } else if (selRow === ("Deletion In Progress")) {
                return {
                    style: { background: theme.palette.oysterProducts.cancelled }
                };
            } else if (selRow === ("Syncing to MYOB")) {
                return {
                    style: { background: theme.palette.oysterProducts.starting }
                };
            }
        },
        expandableRows: true,
        expandableRowsHeader: false,
        expandableRowsOnClick: true,
        isRowExpandable: (dataIndex, expandedRows) => {
            return true;
        },
        renderExpandableRow: (rowData, rowMeta) => {
            const colSpan = rowData.length + 1;
            var lineData = props.filter ? props.filter(procurement)[rowMeta.dataIndex] : procurement[rowMeta.dataIndex];
            console.log(lineData);
            var tenant = process.env.REACT_APP_MYOB_TENANT ? process.env.REACT_APP_MYOB_TENANT : 'AOCLive';
            return (
                <React.Fragment>
                    <TableRow >
                        <TableCell style={{ padding: '0 0 0 15px', fontWeight: 'bold' }} colSpan={2}>MYOBID</TableCell>
                        <TableCell style={{ padding: '0px', fontWeight: 'bold' }} colSpan={2}>Order Date</TableCell>
                        <TableCell style={{ padding: '0px', fontWeight: 'bold' }} colSpan={2}></TableCell>
                    </TableRow>

                    {
                        lineData?.sequenceOrders?.sort((a, b) => {
                            return new Date(a.orderDate) < new Date(b.orderDate) ? -1 : 1;
                        }).map((line) => {
                            var color = 'white';
                            var noteString = '';
                            if (line.outOfSequence) {
                                color = '#F4B585';
                                noteString = 'Manually Adjusted - Won\'t be updated by series';
                            } else {
                                noteString = line.status;
                            }
                            if (line.status === 'Completed') {
                                color = '#C9E2C7';
                                noteString = 'Completed';
                            }
                            if ((line.status === 'Cancelled') || (line.status === 'Canceled')) {
                                color = '#e08083';
                                noteString = 'Cancelled';
                            }
                            //css no wrap
                            var style = { whiteSpace: 'nowrap' };
                            return (
                                <TableRow >
                                    <TableCell style={{ padding: '0 0 0 15px', background: color }} colSpan={2}>{line.myobid ? <Link href={'https://aoc.myobadvanced.com/Main?CompanyID=' + tenant + '&ScreenId=SO301000&OrderType=SO&OrderNbr=' + line.myobid} target='_blank'>{line.myobid}</Link> : 'TBD'}</TableCell>
                                    <TableCell style={{ padding: '0px', background: color }} colSpan={2}>{dateFormat(new Date(line.orderDate))}</TableCell>
                                    <TableCell style={{ padding: '0px', paddingRight: '2px', background: color, whiteSpace: 'nowrap' }} colSpan={2}>{noteString}</TableCell>
                                </TableRow>
                            );
                        })
                    }
                </React.Fragment>


            );
        }
    };
    const getStatus = (startDate, endDate, myobReady, deleting) => {
        var today = new Date();
        if (!myobReady) {
            return 'Syncing to MYOB';
        }
        if (deleting) {
            return 'Deletion In Progress';
        }
        if (today > endDate) {
            return 'Closed';
        } else if ((today > startDate) && (today < endDate)) {
            if ((shiftHours(today, 24 * 14) > endDate)) {
                return 'Ending Soon';
            }
            return 'Active';
        } else if (today < startDate) {
            return 'Not Started';
        }
    }


    const formatTableData = (data) => {
        var textDays = ['S', 'M', 'Tu', 'W', 'Th', 'F', 'S'];
        var newData = data.sort((a, b) => { return new Date(a.startDate) < new Date(b.startDate) ? 1 : -1 }).map((d) => {
            return {
                id: d.id,
                name: d.customer.name,
                status: getStatus(new Date(d.startDate), new Date(d.endDate), d.syncedToMYOB, d.markedForDeletion),
                startDate: dateFormat(d.startDate),
                unFormStartDate: (d.startDate),
                endDate: dateFormat(d.endDate),
                unFormEndDate: (d.endDate),
                frequency: d.frequency,
                days: d.salesSequenceDays.map((s) => {
                    return textDays[s.day];
                }).join(','),
                description: d.description,
                ...grades.reduce((p, c) => {
                    p[String(c.id)] = d.salesSequenceLines.filter((og) => og.stockItem?.gradeId === c.id).reduce((a, b) => {
                        return a + b.quantity;
                    }, 0)
                    return p;
                }, {}),
                nonStockQuantity: d.salesSequenceLines.filter((og) => og.stockItem?.isNonStockItem).reduce((a, b) => {
                    return a + b.quantity;
                }, 0),
                sequenceOrders: d.sequenceOrders
            }

        });
        setProcurement(newData);
    }

    React.useEffect(() => {
        const abortController = new AbortController();
        setProcurement([]);
        setLoading(true);
        if (grades) {
            getAPIDataReturn('/SalesSequence/GetAll').then((data) => {
                if (showCompleted) {
                    getAPIDataReturn('/SalesSequence/GetCompleted').then((data2) => {
                        formatTableData([...data,...data2]);
                        setLoading(false);
                    })
                }
                else {
                    formatTableData(data);
                    setLoading(false);
                }
            })
        }
        return () => {
            abortController.abort();
        };
    }, [props.location, props.startDate, props.endDate, props.reload, grades,showCompleted]);


    return (
        <DataTableStandard
            data={props.filter ? props.filter(procurement) : procurement}
            title={<Typography variant='h2'>Sales Series<FormControlLabel style={{ paddingLeft: '9px' }} control={<Checkbox checked={showCompleted} onChange={(event) => { setShowCompleted(event.target.checked); }} />} label={"Show Completed"} disabled={props.disabled} labelPlacement="left" /></Typography>}
            columns={columns}
            options={options}
            returnSearch={!loading}
            reload={props.reloadFunc}
            shrink={true}

        />
    );
};

export default SalesOrders;
