import React, { useEffect, useState } from 'react';
import MUIDataTable, { TableFilterList } from "mui-datatables";
import { CircularProgress, IconButton, Paper, Typography } from "@mui/material";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import RefreshIcon from '@mui/icons-material/Refresh';
import { numberFormat } from '../utils/numberFunctions';

const DataTableStandard = (props) => {
    const [open, setOpen] = React.useState(true);
    const getMuiTheme = () => createTheme(props.shrink ? {
        components: {
            MUIDataTableBodyCell: {
                styleOverrides: {
                    root: {
                        fontSize: '10px'
                    }
                }
            }
        }
    } : {});

    const WaitForData = () => {
        const [done, setDone] = React.useState(false);

        React.useEffect(() => {
            setTimeout(() => {
                setDone(true);
            }, [26000])
        })
        React.useEffect(() => {
            if (props.returnSearch) {
                setDone(true);
            }
        }, [props.returnSearch])

        if (done) {
            return 'No Results'

        } else {

            return <CircularProgress />
        }
    }
    const fontShrink = (cols) => {
        var newCols = [...cols];
        var settings = {
            setCellProps: () => {
                return {
                    style: {
                        fontSize: '12px',
                        paddingTop: '1px',
                        paddingBottom: '1px',
                        whiteSpace: 'nowrap',
                        maxWidth: '150px',
                        overflow: 'hidden'
                    },
                }
            },
            setCellHeaderProps: (object) => {

                return {
                    style: {
                        margin: '0px',
                        paddingTop: '0px',
                        paddingBottom: '0px',
                        paddingRight: '0px'
                    },
                }
            },
            customHeadLabelRender: (object) => {
                return <span style={{ fontSize: '11px' }}>{object.label}</span>;
            }
        }
        for (var i = 0; i < newCols.length; i++) {
            if (newCols[i]["options"]) {
                newCols[i]["options"] = {
                    ...newCols[i]["options"],
                    ...settings

                }
            } else {
                newCols[i]["options"] = settings;
            }
        }
        return newCols;
    }
    const handleClick = () => {
        setOpen(!open)
        if (props.actionOnClick) {
            props.actionOnClick(!open);
        }
    }
    const Collapsable = () => {
        if (open) {
            return (
                <IconButton onClick={handleClick}>
                    <ExpandLessIcon />
                </IconButton>
            );

        } else {
            return (
                <IconButton onClick={handleClick}>
                    <ExpandMoreIcon />
                </IconButton>
            );
        }
    }
    const Refresh = () => {
        return <IconButton onClick={props.reload ? props.reload : () => { console.log('No Reload Supplied to table') }}>
            <RefreshIcon />
        </IconButton>
    }
    const CustomTBar = () => {
        return <React.Fragment><span><Refresh /></span><span><Collapsable /></span></React.Fragment>
    }
    var options = {
        textLabels: {
            body: {
                noMatch: open ? <WaitForData /> : <div style={{ fontSize: 'x-large' }}>...</div>,
            }
        },
        customToolbar: CustomTBar
    }
    const titleStyle = {
        margin: 0,
        fontSize: "1.5rem",
        fontFamily: "NoirPro, Arial",
        fontWeight: 500,
        lineHeight: 1.6,
        height: "64px",
        paddingLeft: '24px',
        position: 'relative',
        paddingTop: '10px'
    }
    const arrow = {
        position: 'absolute',
        right: '2%',

    }
    if (open) {
        var newData = [...props.data];
        if (props.numberFormat) {
            for (var i = 0; i < newData.length; i++) {
                for (const [key, value] of Object.entries(newData[i])) {
                    newData[i][key] = numberFormat(value);
                }
            }
        }
        return (
            <ThemeProvider theme={null}>
                <MUIDataTable
                    title={<Typography variant={"h3"} >{props.title}</Typography>}
                    data={newData}
                    columns={props.shrink ? fontShrink(props.columns) : props.columns}
                    options={{ ...options, ...props.options }}
                    components={props.components}
                />
            </ThemeProvider>
        );
    } else {
        return (<Paper style={titleStyle} onClick={handleClick}>
            <div style={arrow}><Collapsable /></div>
            <div >{props.title}</div>
        </Paper>);
    }
};

export default DataTableStandard;