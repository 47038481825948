import React from 'react';
import { Select, InputLabel } from "@mui/material";
import { shiftWeek, shiftWeekMonToSun, dateFormat } from "../utils/dateFunctions";
import FormControl from "@mui/material/FormControl";
import Stack from '@mui/material/Stack';
import MenuItem from "@mui/material/MenuItem";
import DatePicker from './DatePicker';

const DateSelector = (props) => {
    const [weekValue, setWeekValue] = React.useState(0);
    const ifSelected = (text, val) => {
        if (val === weekValue) {
            return dateFormat(props.startDate) + ' - ' + dateFormat(props.endDate);
        } else {
            return text;
        }
    }
    return (
        <Stack component="form" noValidate spacing={3}>
            <FormControl sx={{ minWidth: 120 }}>
                <InputLabel id="demo-simple-select-label">Date</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={weekValue}
                    label="Date"
                    onChange={(event) => {
                        var weekVal = Number(event.target.value);
                        if (weekVal < 999) {
                            var dates = props.montosun ? shiftWeekMonToSun(new Date(), weekVal) : shiftWeek(new Date(), weekVal);
                            props.setStartDate(dates.start);
                            props.setEndDate(dates.end);
                        } else {

                        }
                        setWeekValue(weekVal);
                    }}
                >
                    {!props.noBack && !props.onlyCustom ? < MenuItem value={-3}>{ifSelected("Three Weeks Ago", -3)}</MenuItem> : null}
                    {!props.noBack && !props.onlyCustom ? < MenuItem value={- 2}>{ifSelected("Two Weeks Ago", -2)}</MenuItem> : null}
                    {!props.noBack && !props.onlyCustom ? < MenuItem value={- 1}>{ifSelected("Last Week", -1)}</MenuItem> : null}
                    <MenuItem value={0}>{ifSelected("This Week", 0)}</MenuItem>
                    {!props.onlyCustom ? <MenuItem value={1} > {ifSelected("Next Week", 1)}</MenuItem> : null}
                    {!props.onlyCustom ? <MenuItem value={2} > {ifSelected("In Two Weeks", 2)}</MenuItem> : null}
                    { !props.onlyCustom ? <MenuItem value={3} > {ifSelected("In Three Weeks", 3)}</MenuItem> : null}
                    { !props.onlyCustom ? <MenuItem value={4} > {ifSelected("In Four Weeks", 4)}</MenuItem> : null}
                    {!props.noBack? <MenuItem value={1000} > Custom Dates</MenuItem> : null}
                </Select>
            </FormControl>
            {weekValue > 999 ? <React.Fragment>
                <DatePicker date={props.startDate} setDate={props.setStartDate} label="Start Date" />
                <DatePicker date={props.endDate} setDate={props.setEndDate} label="End Date" />
            </React.Fragment> : ''}
        </Stack >);
}
export default DateSelector;