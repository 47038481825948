import React from 'react';
import ControlledTextField from "./ControlledTextField";
import { SelectTextFieldNew } from "./SelectTextField";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { getAPIData } from '../utils/apiFunction';
import CropYearSelect from '../components/CropYearSelect';
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import Stack from '@mui/material/Stack';
import RemoveIcon from '@mui/icons-material/Remove';
import CloseIcon from '@mui/icons-material/Close';
import SelectWarehouse from './SelectWarehouse';
import { ACCESS, ACCESS_SECTIONS } from '../utils/constants/access';
import { checkAccess } from '../utils/roleFunctions';
import { v4 as uuidv4 } from 'uuid';

const OystersGroup = (props) => {
    const [stockItems, setStockItems] = React.useState(null);
    const [priceList, setPriceList] = React.useState(null);
    const sortStockItems =(setFunc)=> (list) => {
        setFunc(list.sort((a, b) => {
            if(a.grade?.sortOrder === b.grade?.sortOrder){
                return -a.myobid.localeCompare(b.myobid);
            }
               return a.grade?.sortOrder - b.grade?.sortOrder;
        }));
    }

    React.useEffect(() => {
        getAPIData(!props.processed ? '/StockItems/GetUnprocessed' : (props.nonStock ? '/StockItems/GetNonStock' : '/StockItems/GetProcessed'), sortStockItems(setStockItems));
    }, [])
    React.useEffect(() => {
        if (props.customerId) {
            if (props.supplier) {
                getAPIData('/SupplierPricing/GetBySupplier/' + props.customerId, setPriceList);
            } else {
                getAPIData('/CustomerPricing/GetByCustomer/' + props.customerId, setPriceList);
            }
        }
    }, [props.customerId]);
    
    const reduceStockItems = (stockItems, oL) => {
        if (stockItems) {
            return stockItems.filter((si) => {
                if (props.oysters.find((ol) => ol.stockItem?.id === si.id)) {
                    if (oL.stockItem?.id === si.id) {
                        return true;
                    }
                    return false;
                } else {
                    return true;
                }
            })
        }
        else {
            return stockItems;
        }
    }
    const addLine = () => {
        var oysterListCPY = [...props.oysters];
        oysterListCPY.push({ tempId: uuidv4() });
        props.setOysters(oysterListCPY);
    }
    const removeLine = () => {
        var oysterListCPY = [...props.oysters];
        oysterListCPY.pop({});
        props.setOysters(oysterListCPY);
    }
    const setLine = (valToUpdate, ind) => {
        props.setOysters(oyLt => {
            var oysterListCPY = JSON.parse(JSON.stringify(oyLt));
            oysterListCPY[ind][valToUpdate.name] = valToUpdate.value;
            return oysterListCPY;
        });
    }
    const removeSpecficLine = (ind) => (e) => {
        props.setOysters(oyLt => {
            var oysterListCPY = JSON.parse(JSON.stringify(oyLt));
            oysterListCPY.splice(ind, 1);
            return oysterListCPY;
        });
    }
    console.log(props.oysters);
    return (<React.Fragment>
        {!props.noTitle ? <Grid item xs={12} >
            <h3 style={{ fontWeight: 450 }}>{!props.nonStock ? "Oysters" : "Non Stock Item"}</h3>
        </Grid> : ''}
        {props.oysters?.map((oL, ind) => {
            return <div style={{ position: 'relative' }} ><OysterLine siStyle={props.siStyle} halfSize={props.halfSize} key={oL.id ? oL.id : oL.tempId} data={oL} items={ reduceStockItems(stockItems, oL)} setLine={setLine} index={ind} disabled={props.disabled} hideWarehouse={props.hideWarehouse} nonStock={props.nonStock} supplier={props.supplier} priceList={priceList} access={props.access} />
                <IconButton aria-label="remove" onClick={removeSpecficLine(ind)} disabled={props.disabled} style={{
                    position: 'absolute',
                    right: '0px',
                    top: '30%'
                }} >
                    <CloseIcon />
                </IconButton>
            </div>;
        })}
        {!props.single ? <React.Fragment><Stack direction="row" spacing={1}>
            <IconButton aria-label="add" onClick={addLine} disabled={props.disabled}>
                <AddIcon />
            </IconButton>
            <IconButton aria-label="remove" onClick={removeLine} disabled={props.disabled}>
                <RemoveIcon />
            </IconButton>
        </Stack>
            {!props.nonStock ? <ControlledTextField title="Total Quantity (doz)" state={totalQuantity(props.oysters)} disabled number /> : ''}
        </React.Fragment> : ''}

    </React.Fragment>)
}
const totalQuantity = (list) => {
    return list.reduce((p, c) => { return p + (c.quantity ? Number(c.quantity) : 0) }, 0)
}
const OysterLine = (props) => {
    const [first, setFirst] = React.useState(true);

    const [changePrice, setChangePrice] = React.useState(props.data ? props.data.changePrice : false);
    const requiredPricingAccess = { key: ACCESS_SECTIONS.PROCUREMENT, value: ACCESS.ADMIN };
    var ol = props.data;

    const remSS = (inp) => {
        if (inp.description.includes('Single Seed')) {
            return inp.description.replace('Single Seed', '');
        } else {
            return inp.description;
        }
    }

    const updateFunction = (name) => (val) => {
        var pv = !props.nonStock && ol.stockItem && props.priceList && (Number(ol.price) !== (props.priceList.find((pl) => { return pl.stockItemId === ol.stockItem.id })?.price))
        props.setLine({ name: 'priceVary', value: pv }, props.index);
        if (name === 'stockItem') {
            if (val?.warehouseId && !ol.warehouseId) {
                props.setLine({ name: 'warehouseId', value: val.warehouseId }, props.index);
            }
            var nPrice = props.priceList?.find((pl) => { return pl.stockItemId === val?.id })?.price;
            if (nPrice) {
                props.setLine({ name: 'price', value: nPrice }, props.index);
            }
        }
        if (name === 'warehouse') {
            if (val?.id) {
                props.setLine({ name: 'warehouseId', value: val?.id }, props.index);
            }
        }
        props.setLine({ name: name, value: val }, props.index);

    }
    var siWidth = { width: '400px' };
    return <Grid key={props.key} container spacing={2} mb={1} style={{ borderBottom: '1px solid lightgrey', marginTop: '10px' }}>
        {props.nonStock ?
            <SelectTextFieldNew useAutoComplete style={props.siStyle ? { ...siWidth, ...props.siStyle } : siWidth} key={'nsi' + props.key} title="Non Stock Item" state={ol.stockItem} nameFunc={remSS} valueFunc={v => v?.id} list={props.items} setState={updateFunction('stockItem')} disabled={props.disabled} /> :

            <SelectTextFieldNew useAutoComplete style={props.siStyle ? { ...siWidth, ...props.siStyle } : siWidth} key={'si' + props.key} title="Stock Item" state={ol.stockItem} nameFunc={remSS} valueFunc={v => v?.id} list={props.items} setState={updateFunction('stockItem')} disabled={props.disabled} />
        }

        <ControlledTextField key={'qua' + props.key} title="Quantity (doz)" state={ol.quantity} setState={updateFunction('quantity')} disabled={props.disabled} number />

        <ControlledTextField key={'pr' + props.key} title="Price" state={ol.price} setState={updateFunction('price')} disabled={props.disabled} number />

        {!props.hideWarehouse?<SelectWarehouse auto key={'sw' + props.key} warehouseId={ol.warehouseId} state={ol.warehouse} setState={updateFunction('warehouse')} disabled={props.disabled} all={props.supplier ? false : true} />:null}

        {!props.nonStock && ol.stockItem && props.priceList && (Number(ol.price) !== (props.priceList.find((pl) => { return pl.stockItemId === ol.stockItem.id })?.price)) ?
            <React.Fragment>
                <TextField
                    disabled={props.disabled}
                    id="outlined-multiline-static"
                    label="Reason for Variation"
                    value={ol.variationReason}
                    onChange={(event) => { updateFunction('variationReason')(event.target.value) }}
                    multiline
                    InputLabelProps={{
                        shrink: true,
                    }}
                    rows={4} />
                {checkAccess(props.access, requiredPricingAccess) ? <Grid item xs={12} sm={6} md={6} lg={6} xl={props.halfSize ? 6 : 3}>
                    <FormControlLabel style={{ paddingLeft: '9px' }} control={<Checkbox checked={changePrice} onChange={(event) => { updateFunction('changePrice')(event.target.checked); }} />} label={"Change this " + (props.supplier ? "Suppliers" : "Customers") + " Default Price?"} disabled={props.disabled} labelPlacement="right" />
                </Grid> : null}
            </React.Fragment> : ''}



    </Grid>
}
export default OystersGroup;