import React, { useMemo } from 'react';
import { dateTimeFormat, AmountOfTimeToText, textfieldDateFormat, generateTextDays, generateDates, dayCompare } from "../../../utils/dateFunctions";
import MUIDataTable from "mui-datatables";
import { getOysterGrades, gradeSort } from "../../../definitions/oysters";
import { getAPIData, getAPIDataParamsReturn, postApi } from "../../../utils/apiFunction";
import { arrayMove, SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { ACCESS, ACCESS_SECTIONS } from '../../../utils/constants/access';
import FormModal from '../../../components/FormModal';
import ProcessStockForm from '../../Processing/Forms/ProcessStockForm';
import { Table, TableBody, TableRow, TableCell, Avatar, TableHead, ToggleButtonGroup } from "@mui/material";
import SelectTextField from "../../../components/SelectTextField";
import TableContainer from "@mui/material/TableContainer";
import ToggleButton from '@mui/material/ToggleButton';
import { useJumboTheme } from "@jumbo/hooks";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { checkAccess } from '../../../utils/roleFunctions';
import { DownloadButton } from '../../../utils/downloadUtils';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import Button from "@mui/material/Button";
import Switch from "@mui/material/Switch";
import { displayDozFormat, numberFormat, numberFormatwithNeg } from '../../../utils/numberFunctions';
import CircularProgress from '@mui/material/CircularProgress';
import { ViewQAButton } from "../../../components/TableItemViews";
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import FormControlLabel from '@mui/material/FormControlLabel';
import { isDate } from 'date-fns-jalali';
import { FunnelChart, Funnel, Tooltip, LabelList } from 'recharts';


const requiredEditAccess = { key: ACCESS_SECTIONS.PROCESSING, value: ACCESS.ADMIN };
const requiredMYOBAccess = { key: ACCESS_SECTIONS.PROCESSING, value: ACCESS.MYOB };
const requiredNewFeatureAccess = { key: ACCESS_SECTIONS.NEWFEATURE, value: ACCESS.ADMIN };
const CompletedRuns = (props) => {
    const uri = process.env.REACT_APP_BASE_URL;
    const [open, setOpen] = React.useState(false);
    const [edit, setEdit] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [columns, setColumns] = React.useState([]);
    const [procurement, setProcurement] = React.useState(null);
    const [unlock, setUnlock] = React.useState(false);
    const [selectedLine, setSelectedLine] = React.useState(null);
    const [reload, setReload] = React.useState(false);
    const [grades, setGrades] = React.useState([]);
    const [belts, setBelts] = React.useState([]);
    const [displayPerc, setDisplayPerc] = React.useState(true);
    var showOrderButton = false;

    const onSortEnd = ({ oldIndex, newIndex }) => {
        setProcurement(arrayMove(procurement, oldIndex, newIndex));
    };
    const updateProcurement = (index, key) => (value) => {
        var proc = [...procurement];
        proc[index][key] = value;
        setProcurement(proc);
    };
    React.useEffect(() => {
        getOysterGrades().then((data) => {
            setGrades(gradeSort(data));
        });
    }, []);
    React.useEffect(() => {
        getAPIData('/Visualisation/Belts', setBelts)
    }, []);
    React.useEffect(() => {
        setProcurement([]);
        if (props.startDate && !isNaN(props.startDate.valueOf())) {
            getProcurementData(props.location, textfieldDateFormat(props.startDate), textfieldDateFormat(props.endDate));
        }
    }, [props.location, props.startDate, props.endDate, props.reload, reload]);

    var getProcurementData = async (location, startDate, endDate) => {
        setLoading(true);
        if (location) {
            var data = await getAPIDataParamsReturn('/Processing/GetComplete', { WarehouseId: location, Date: startDate, EndDate: endDate });
            //get receival
            setProcurement(data);
            setLoading(false);
        }
    }
    const unlockLock = (stat) => () => {
        setUnlock(stat);
    }

    const convertData = (data) => {
        if (data) {
            var newData = data.map((item) => {
                var output = item?.processRunOutputs.reduce((p, c) => { return p + Number(c.quantity) }, 0);
                var newObj = {
                    "Lot Nbr": item.coreInventory?.lotSerialNbr,
                    "Supplier": item.coreInventory?.receival?.supplier?.name,
                    "Input Grade": remSS(item.stockItem?.description),
                    "Input Code": item.stockItem?.myobid,
                    "Input Quantity": item?.quantity,
                    "Output Quantity": output,
                    "Bins": item.bins,
                    "Bags": item.bags,
                    "Variance Quantity": item.totalVariance,
                    "Variance %": item?.percentageVariance,
                    "Appelation Quantity": item.totalAppellation,
                    "Premium Quantity": item.totalPremium,
                    "Upgraded Quantity": item.totalUpgraded,
                    "Downgraded Quantity": item.totalDowngraded,
                    "Appelation %": item?.percentageAppellation,
                    "Premium %": item?.percentagePremium,
                    "Upgraded %": item?.percentageUpgraded,
                    "Downgraded %": item?.percentageDowngraded,
                    "Graded": item.coreInventory?.graded,
                    "Process Type": item.coreInventory?.processType?.replaceAll(', ', '-'),
                    "Start": dateTimeFormat(item.start),
                    "End": dateTimeFormat(item.finish),
                    "Total Time (s)": ((new Date(item.finish).getTime() - new Date(item.start).getTime()) / 1000),
                    "Total Time": AmountOfTimeToText((new Date(item.finish).getTime() - new Date(item.start).getTime()) / 1000),

                };

                for (var i = 0; i < grades.length; i++) {
                    newObj[grades[i].abv] = item?.processRunOutputs?.filter((pr) => { return pr?.stockItem?.gradeId === grades[i].id })?.reduce((p, c) => { return p + c.quantity }, 0);
                }
                return newObj;
            });
            return (newData);
        } else {
            return null;
        }
    }
    const filterSupplier = (supplier, data) => {
        if (data) {
            return data.filter((dt) => { return dt.coreInventory?.receival?.supplier?.id === supplier });
        } else {
            return null;
        }
    }


    const processOysters = (data) => (edit) => {
        if (!unlock) {
            setEdit(!!edit);
            setOpen(true);
            setSelectedLine(data);
        }
    }

    const handleClose = () => {
        setOpen(false);
        setReload(!reload);
        props.reloadPage();

    }
    return (
        <JumboCardQuick
            title={<div>Completed Runs <DownloadButton start={true} setStart={() => { }} data={convertData(props.supplierFilter?filterSupplier(props.supplierFilter,procurement):procurement)} /><FormControlLabel style={{ marginLeft: '15px' }} control={<Switch checked={displayPerc} onChange={(event) => { setDisplayPerc(event.target.checked) }} />} label={displayPerc ? 'Percentage' : 'Total Oysters'} /></div>}
            wrapperSx={{...{
                p: 0,
                backgroundColor: 'background.paper',
                zIndex: 0,
                '&:last-child': {
                    pb: 0
                }
                
            },...(props.supplierFilter?{maxHeight:'700px',overflowY:'auto'}:{})}}
        >

            <DragTable belts={belts} {...props} displayPerc={displayPerc} data={props.supplierFilter?filterSupplier(props.supplierFilter,procurement):procurement} onSortEnd={onSortEnd} unlock={unlock} processOysters={processOysters} updateProcurement={updateProcurement} loading={loading} />
            <FormModal open={open} handleClose={handleClose} title={"Process Task"} fixedWidth>
                <ProcessStockForm access={props.access} handleClose={handleClose} selectedLine={selectedLine} completed edit={edit} warehouseId={props.location} />
            </FormModal >
        </JumboCardQuick>

    );
};

const DragTable = ((props) => {
    var data = props.data;
    var totalOutput = data?.reduce((p, c) => { return p + Number(c?.processRunOutputs.reduce((p, c) => { return p + Number(c.quantity) }, 0)) }, 0);
    var sumquantity = data?.reduce((p, c) => { return p + Number(c.quantity) }, 0);
    var sumtotalVariance = data?.reduce((p, c) => { return p + Number(c.totalVariance) }, 0);
    var sumtotalAppellation = data?.reduce((p, c) => { return p + Number(c.totalAppellation) }, 0);
    var sumtotalPremium = data?.reduce((p, c) => { return p + Number(c.totalPremium) }, 0);
    var sumtotalUpgraded = data?.reduce((p, c) => { return p + Number(c.totalUpgraded) }, 0);
    var sumtotalDowngraded = data?.reduce((p, c) => { return p + Number(c.totalDowngraded) }, 0);
    var sumpercentageVariance = sumtotalVariance / sumquantity * 100;
    var sumpercentageAppellation = sumtotalAppellation / totalOutput * 100;
    var sumpercentagePremium = sumtotalPremium / totalOutput * 100;
    var sumpercentageUpgraded = sumtotalUpgraded / totalOutput * 100;
    var sumpercentageDowngraded = sumtotalDowngraded / totalOutput * 100;

    return (
        <TableContainer >
            <Table sx={{ minWidth: 650 }}>
                <TableHead>
                    <TableRow>
                        <TableCell>

                        </TableCell>
                        <TableCell>
                            PRId
                        </TableCell>
                        <TableCell>
                            Lot Nbr
                        </TableCell>
                        <TableCell>
                            Supplier
                        </TableCell>

                        <TableCell>
                            Input Grade
                        </TableCell>
                        <TableCell>
                            Input Quantity (Doz)
                        </TableCell>
                        <TableCell>
                            QA
                        </TableCell>
                        <TableCell>
                            Variance
                        </TableCell>
                        <TableCell>
                            Appellation
                        </TableCell>
                        <TableCell>
                            Premium
                        </TableCell>
                        <TableCell>
                            Upgraded
                        </TableCell>
                        <TableCell>
                            Downgraded
                        </TableCell>
                        <TableCell>
                            Graded/Ungraded
                        </TableCell>
                        <TableCell>
                            Process Type
                        </TableCell>
                        <TableCell>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {!props.loading ? data?.map((dt, index) => (
                        <POCellSortable belts={props.belts} key={index} displayPerc={props.displayPerc} index={index} ind={index} data={dt} processOysters={props.processOysters} unlock={props.unlock} updateProcurement={props.updateProcurement} access={props.access} />
                    )) : <CircularProgress />}
                    <TableRow style={{ zIndex: 1000, }} >
                        <TableCell >
                            <Typography variant={"h6"} style={{ textAlign: 'right', fontWeight: 'bold' }} mb={0}>Total</Typography>
                        </TableCell>
                        <TableCell >
                        </TableCell>
                        <TableCell >
                        </TableCell>
                        <TableCell >
                        </TableCell>
                        <TableCell >
                        </TableCell>
                        <TableCell >
                            <Typography variant={"h6"} style={{ textAlign: 'right' }} mb={0}>{numberFormat(sumquantity)}</Typography>
                        </TableCell>
                        <TableCell >
                        </TableCell>
                        <TableCell >
                            <Typography variant={"h6"} style={{ textAlign: 'right' }} mb={0}>{props.displayPerc ? formatPercent(sumpercentageVariance) : numberFormatwithNeg(sumtotalVariance)}</Typography>
                        </TableCell>
                        <TableCell >
                            <Typography variant={"h6"} style={{ textAlign: 'right' }} mb={0}>{props.displayPerc ? formatPercent(sumpercentageAppellation) : numberFormatwithNeg(sumtotalAppellation)}</Typography>
                        </TableCell>
                        <TableCell >
                            <Typography variant={"h6"} style={{ textAlign: 'right' }} mb={0}>{props.displayPerc ? formatPercent(sumpercentagePremium) : numberFormatwithNeg(sumtotalPremium)}</Typography>
                        </TableCell>
                        <TableCell >
                            <Typography variant={"h6"} style={{ textAlign: 'right' }} mb={0}>{props.displayPerc ? formatPercent(sumpercentageUpgraded) : numberFormatwithNeg(sumtotalUpgraded)}</Typography>
                        </TableCell>
                        <TableCell >
                            <Typography variant={"h6"} style={{ textAlign: 'right' }} mb={0}>{props.displayPerc ? formatPercent(sumpercentageDowngraded) : numberFormatwithNeg(sumtotalDowngraded)}</Typography>
                        </TableCell>
                        <TableCell >
                        </TableCell>
                        <TableCell >
                        </TableCell>
                        <TableCell >
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
});

const remSS = (inp) => {
    if (inp) {
        if (inp.includes('Single Seed')) {
            return inp.replace('Single Seed', '');
        } else {
            return inp;
        }
    }
}
const formatPercent = (inp) => {
    if (inp) {
        return inp.toFixed(2) + '%';
    } else return '0%';
}
const POCellSortable = props => {

    const requiredAdminAccess = { key: ACCESS_SECTIONS.PROCESSING, value: ACCESS.ADMIN };
    const [open, setOpen] = React.useState(false);
    const [counterOutcomes, setCounterOutcomes] = React.useState([]);
    const { data } = props;
    const [status, setStatus] = React.useState(props.belts.map((blt) => { return blt.id }));
    const [starred, setStarred] = React.useState(data.starred);
    const { theme } = useJumboTheme();

    var getCol = theme.palette.purchaseOrderStatus.completed;
    if (data.hadMYOBError) {
        getCol = theme.palette.purchaseOrderStatus.red;
    }

    React.useEffect(() => {
        getAPIData('/Visualisation/CounterRecords/' + data.id, setCounterOutcomes)
    }, []);

    const remSS = (inp) => {
        if (inp) {
            if (inp.includes('Single Seed')) {
                return inp.replace('Single Seed', '');
            } else {
                return inp;
            }
        }
    }
    const productPalette = theme.palette.oysterProducts;
    const convertBagCount = (bc) => {
        if (bc) {
            if(bc === 600){
                return 'Plate'
            }else if(bc === 720){
                return 'Bistro'
            }else if(bc === 840){
                return 'Bottle'
            }else if(bc === 960){
                return 'Cocktail'
            }else {
                return bc
            }
        }
    }
    var sizes = [
        {
            name: '< Cocktail',
            range: [0, 40],
            fill: productPalette.A,
            groups: []

        },
        {
            name: 'Cocktail',
            range: [40, 50],
            fill: productPalette.B,
            groups: []
        },
        {
            name: 'Bottle',
            range: [50, 60],
            fill: productPalette.C,
            groups: []
        },
        {
            name: 'Bistro',
            range: [60, 75],
            fill: productPalette.D,
            groups: []
        },
        {
            name: 'Plate',
            range: [75, 90],
            fill: productPalette.E,
            groups: []
        },
        {
            name: 'XL',
            range: [90, 1000],
            fill: productPalette.F,
            groups: []
        }
    ];
    var measures = counterOutcomes?.processCounterMeasures?.sort((a, b) => { return a.mm > b.mm ? 1 : -1 })
    var perSection = 3;
    var totalAICount = 0;
    if (measures) {
        for (var i = 0; i < measures.length; i++) {
            if ( (status.includes(measures[i].beltId ))) {
                for (var j = 0; j < sizes.length; j++) {
                    for (var l = 0; l < perSection; l++) {
                        var division = (sizes[j].range[1] - sizes[j].range[0]) / perSection;
                        if (measures[i].mm >= sizes[j].range[0] + division * l && measures[i].mm < sizes[j].range[0] + division * (l + 1)) {
                            if (sizes[j].groups[l]) {
                                sizes[j].groups[l] = sizes[j].groups[l] + measures[i].count
                            } else {
                                sizes[j].groups[l] = measures[i].count;
                            }

                        }
                    }
                }
                totalAICount += measures[i].count;
            }
        }
    }
    console.log(sizes)

    const sortByBelt = (data) => {
        var fullList = [];
        for (var i = 0; i < data.length; i++) {
            if (fullList.find((fl) => { return (fl.beltId === data[i].beltId) && (fl.bagCount === data[i].bagCount) })) {
                fullList.find((fl) => { return (fl.beltId === data[i].beltId) && (fl.bagCount === data[i].bagCount) }).boxList.push(data[i]);
            } else {
                fullList.push({ beltId: data[i].beltId,bagCount:data[i].bagCount, description: ((props.belts.find((blt) => (blt.id === data[i].beltId)))?.description+" ("+convertBagCount(data[i].bagCount)+")"), boxList: [data[i]] });
            }
        }
        return fullList;
    }
    var sortedByBelt = [];
    if (counterOutcomes && counterOutcomes.processCounterBoxess && (counterOutcomes.processCounterBoxess.length > 0)) {
        sortedByBelt = sortByBelt(counterOutcomes.processCounterBoxess);
    }
    return (
        //row colour based on status refer to customer order shet
        <React.Fragment>
            <TableRow style={{ zIndex: 1000, backgroundColor: getCol }} onClick={(e) => { setOpen(!open) }}>
                <TableCell >
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={(e) => { setOpen(!open) }}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell >
                    <Typography variant={"h6"} mb={0}>{data?.id}</Typography>
                </TableCell>
                <TableCell >
                    <Typography variant={"h6"} mb={0}>{data?.coreInventory?.lotSerialNbr}</Typography>
                </TableCell>
                <TableCell >
                    <Typography variant={"h6"} mb={0}>{data.coreInventory?.receival?.supplier?.name}</Typography>
                </TableCell>

                <TableCell >
                    <Typography variant={"h6"} mb={0}>{remSS(data.stockItem?.description)}</Typography>
                </TableCell>

                <TableCell >
                    <Typography variant={"h6"} style={{ textAlign: 'right' }} mb={0}>{numberFormat(data?.quantity)}</Typography>
                </TableCell>
                <TableCell >
                    {data.coreInventory?.receival?.qaId ? <ViewQAButton access={props.access} id={data.coreInventory?.receival?.qaId} recId={data.coreInventory?.receivalId} /> : null}
                </TableCell>
                <TableCell >
                    <Typography variant={"h6"} style={{ textAlign: 'right' }} mb={0}>{props.displayPerc ? formatPercent(data?.percentageVariance) : numberFormatwithNeg(data?.totalVariance)}</Typography>
                </TableCell>
                <TableCell >
                    <Typography variant={"h6"} style={{ textAlign: 'right' }} mb={0}>{props.displayPerc ? formatPercent(data?.percentageAppellation) : numberFormatwithNeg(data?.totalAppellation)}</Typography>
                </TableCell>
                <TableCell >
                    <Typography variant={"h6"} style={{ textAlign: 'right' }} mb={0}>{props.displayPerc ? formatPercent(data?.percentagePremium) : numberFormatwithNeg(data?.totalPremium)}</Typography>
                </TableCell>

                <TableCell >
                    <Typography variant={"h6"} style={{ textAlign: 'right' }} mb={0}>{props.displayPerc ? formatPercent(data?.percentageUpgraded) : numberFormatwithNeg(data?.totalUpgraded)}</Typography>
                </TableCell>
                <TableCell >
                    <Typography variant={"h6"} style={{ textAlign: 'right' }} mb={0}>{props.displayPerc ? formatPercent(data?.percentageDowngraded) : numberFormatwithNeg(data?.totalDowngraded)}</Typography>
                </TableCell>
                <TableCell >
                    <Typography variant={"h6"} mb={0}>{data?.coreInventory?.graded}</Typography>
                </TableCell>
                <TableCell >
                    <Typography variant={"h6"} mb={0}>{(data?.coreInventory?.processType)}</Typography>
                </TableCell>
                <TableCell >
                    {checkAccess(props.access, requiredMYOBAccess) ? <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={(e) => { e.stopPropagation(); props.processOysters(data)(true) }}
                    >
                        <EditIcon />
                    </IconButton> : null}
                    {/*<IconButton*/}
                    {/*    aria-label="expand row"*/}
                    {/*    size="small"*/}
                    {/*    onClick={(e) => { e.stopPropagation(); props.processOysters(data)() }}*/}
                    {/*>*/}
                    {/*    <VisibilityIcon />*/}
                    {/*</IconButton>*/}
                </TableCell>

            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Typography varaint={'h4'} style={{ color: 'red' }} >{data?.hadMYOBError ? 'There has been an error pushing to MYOB, please check with IT' : ''}</Typography>
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>StockItem</TableCell>
                                        <TableCell>Units</TableCell>
                                        <TableCell>Quantity (doz)</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data?.processRunOutputs?.map((gt, k) => {
                                        return <TableRow key={k}>

                                            <TableCell component="th" scope="row">
                                                {gt.stockItem.description}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {gt.noOfUnits}{((gt.quantity / gt.stockItem.bagCount) !== gt.noOfUnits) ? (" (" + (gt.quantity / gt.stockItem.bagCount).toFixed(1) + ")") : ''}
                                            </TableCell>
                                            <TableCell align="right">{numberFormat(gt.quantity)}</TableCell>
                                        </TableRow>
                                    })}

                                </TableBody>
                            </Table>
                            {checkAccess(props.access, requiredNewFeatureAccess) && counterOutcomes && counterOutcomes.processCounterBoxess && (counterOutcomes.processCounterBoxess.length > 0) ?
                                <div>
                                    <Typography varaint={'h3'} >Box Counts</Typography>

                                    {sortedByBelt.sort((a, b) => (a.description > b.description ? 1 : -1)).map((bl) => {
                                        return <div><Typography varaint={'h3'} style={{ textDecoration: 'underline' }}>{bl.description}  {bl.bagCount}</Typography>
                                            <Typography varaint={'h6'} >{bl.boxList.reduce((p, c) => { return p + 'Box ' + c.boxId + ': ' + c.count + ',  ' }, '')}</Typography>
                                        </div>
                                    })}

                                </div>
                                : null}
                        </Box>
                    </Collapse>
                </TableCell>
                {checkAccess(props.access, requiredNewFeatureAccess) && counterOutcomes ? <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <ToggleButtonGroup value={status} onChange={(e, v) => { setStatus(v) }} color="primary" >
                            {props.belts.sort((a, b) => (a.description > b.description ? 1 : -1)).map(blt => (<ToggleButton value={blt.id}>{blt.description}</ToggleButton>))}
                        </ToggleButtonGroup>

                        <Table size="small" aria-label="purchases" style={{ border: '2px solid red' }} >
                            <TableHead>
                                <TableRow>
                                    <TableCell>Grade</TableCell>
                                    <TableCell align="right">Production-Quantity (doz)</TableCell>
                                    <TableCell align="right">AI-Quantity (doz)</TableCell>
                                    <TableCell align="right">AI-Quantity Breakdown(doz)</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {sizes.map((oz, k) => {


                                    var totalQ = data?.processRunOutputs.reduce((p, c) => { return p + c.quantity }, 0);
                                    var prodQuant = data?.processRunOutputs.reduce((p, c) => { return p + (c.stockItem.description.includes(oz.name) ? c.quantity : 0) }, 0);
                                    //group measures into array based on mm


                                    return <TableRow key={k}>
                                        <TableCell component="th" scope="row">
                                            {oz.name}
                                        </TableCell>
                                        <TableCell align="right">
                                            <TableRow >
                                                {numberFormat(prodQuant)} ({formatPercent(Number(prodQuant) / Number(totalQ) * 100)})
                                            </TableRow >
                                        </TableCell>
                                        <TableCell align="right">
                                            {numberFormat((Number(oz.groups.reduce((p, c) => (p + c), 0)) / 12).toFixed(0))} ({formatPercent(Number(oz.groups.reduce((p, c) => (p + c), 0)) / Number(totalAICount) * 100)})
                                        </TableCell>
                                        <TableCell align="right">
                                            {oz.groups?.map((gt, k) => {
                                                return <TableRow >
                                                    <TableCell align="right">
                                                        {numberFormat((Number(gt) / 12).toFixed(0))} ({formatPercent(Number(gt) / Number(totalAICount) * 100)})
                                                    </TableCell>
                                                </TableRow >
                                            })}
                                        </TableCell>
                                    </TableRow>
                                })}
                                <TableRow>
                                    <TableCell style={{ fontWeight: 'bold' }} >Total</TableCell>
                                    <TableCell align="right">{numberFormat(data?.processRunOutputs.reduce((p, c) => { return p + c.quantity }, 0))}</TableCell>
                                    <TableCell align="right">{numberFormat((Number(totalAICount) / 12).toFixed(0))}</TableCell>
                                </TableRow>

                            </TableBody>
                        </Table>
                    </Collapse>
                </TableCell> : null}


            </TableRow>
        </React.Fragment >

    );
};


export default CompletedRuns;
