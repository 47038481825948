import React from 'react';
import Grid from "@mui/material/Grid";
import PurchaseOrders from '../Procurement/Tables/PurchaseOrders';
import { Button, Checkbox, Container, FormControlLabel, FormGroup, Typography } from "@mui/material";
import { yesterday, tomorrow } from "../../utils/dateFunctions";
import ReceivalForm from '../Processing/Forms/ReceivalForm';
import SelectWarehouse from '../../components/SelectWarehouse';

const NewReceival = (props) => {
    const [purchaseOrder, setPurchaseOrder] = React.useState(null);
    const [hasPurchaseOrder, setHasPurchaseOrder] = React.useState(true);
    const [startDate, setStartDate] = React.useState(yesterday(yesterday(yesterday(yesterday(yesterday(new Date()))))));
    const [endDate, setEndDate] = React.useState((tomorrow(new Date())));
    const [warehouse, setWarehouse] = React.useState(null);
    const handleChange = (event) => {
        setHasPurchaseOrder(event.target.checked);
        if (event.target.checked === false) {
            setPurchaseOrder(null);
        }
    };
    const selectPO = (array) => (rowd, rowState) => {
        console.log(array)
        setPurchaseOrder(array[rowState.dataIndex]);
    }
    const deselectPO = (array) => (rowd, rowState) => {
        setPurchaseOrder(null);
    }
    const selectId = (list) => {
        return list.filter((l) => {
            if (purchaseOrder) {
                return l.id === purchaseOrder.id;
            } else {
                return true;
            }
        })
    }
    return (
        <Container style={{paddingTop:'20px'} }>
            <Grid container spacing={2} mb={1}>
                <Typography  variant={'h2'}>Select Purchase Order</Typography>
                <Grid item xs={12} >
                    <FormGroup>
                        <FormControlLabel control={<Checkbox checked={hasPurchaseOrder} onChange={handleChange} />} label="Purchase Order Exists" />
                    </FormGroup>
                </Grid>
                <Grid item xs={12} >
                    <SelectWarehouse useCache warehouseId={null} state={warehouse} setState={setWarehouse} disabled={false} />
                </Grid>
                {(!hasPurchaseOrder || purchaseOrder) ? '' : <Grid item xs={12} >
                    <PurchaseOrders warehouseFilter={warehouse?.description} location={0} access={props.access} onClick={selectPO} startDate={startDate} endDate={endDate} notReceived/>
                </Grid>}
                {(purchaseOrder && hasPurchaseOrder) ? <Grid item xs={12} >
                    <PurchaseOrders location={0} access={props.access} onClick={deselectPO} startDate={startDate} endDate={endDate} filter={selectId} notReceived />
                </Grid> : ''}
                {(purchaseOrder || !hasPurchaseOrder) ? <Grid item xs={12} >
                    <ReceivalForm data={purchaseOrder} access={props.access} handleClose={props.handleClose}/>
                </Grid> : ''}

            </Grid>


        </Container>

    );
};

export default NewReceival;