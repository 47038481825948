import React from 'react';
import { dateFormat, textfieldDateFormat, generateTextDays, generateDates, dayCompare } from "../../../utils/dateFunctions";
import MUIDataTable from "mui-datatables";
import { getOysterGrades, gradeSort } from "../../../definitions/oysters";
import { getAPIDataParamsReturn, handlePagination } from "../../../utils/apiFunction";
import { useJumboTheme } from "@jumbo/hooks";
import MoveToInboxIcon from '@mui/icons-material/MoveToInbox';
import VerifiedIcon from '@mui/icons-material/Verified';
import { TableCell, TableRow, TableFooter, Tab } from "@mui/material";
import { ExpandButton } from "mui-datatables";
import { CircularProgress } from "@mui/material";
import { ThemeProvider } from '@mui/material/styles';
import Link from "@mui/material/Link";
import { tableTheme } from '../../../themes/tableTheme';
import { adalApiFetch } from '../../../../adalConfig';
import DataTableStandard from "../../../components/DataTableStandard";
import DayToggle from "../../../components/DayToggle";
import IconButton from '@mui/material/IconButton';
import { numberFormat, numberFormatwithNeg } from '../../../utils/numberFunctions';
import { dateSort, reverseSort, colRemoval } from '../../../utils/sortFunctions';
import FormModal from '../../../components/FormModal';
import ConfirmReceivalForm from "../../Processing/Forms/ConfirmReceivalForm";
import QAform from '../../Processing/Forms/QAform';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { ViewQAButton, ViewReceivalButton } from '../../../components/TableItemViews';

const PurchaseOrders = (props) => {
    const uri = process.env.REACT_APP_BASE_URL;
    const { theme } = useJumboTheme();
    const [loading, setLoading] = React.useState(false);
    const [columns, setColumns] = React.useState([]);
    const [daySelection, setDaySelection] = React.useState([generateDates(props.startDate, props.endDate).findIndex((vl) => { return dayCompare(vl, new Date()) })]);
    const [procurement, setProcurement] = React.useState([]);
    const [reload, setReload] = React.useState(true);
    const [total, setTotal] = React.useState([]);
    const [grades, setGrades] = React.useState([]);
    const reloadFunc = () => {
        setReload(!reload);
        if(props.exReloadFunc){
            props.exReloadFunc();
        }
    }
    const duplicatePO = (dI) => () => {
        if (props.duplicatePO) {
            props.handleDuplicate();
        }
    }
    React.useEffect(() => {
        getOysterGrades().then((data) => {
            setGrades(gradeSort(data));
        });
    }, []);
    const checkIfWeekTotal = (dataIndex) => {
        var proc = props.filter ? props.filter(procurement) : procurement;
        if (proc?.[dataIndex]?.comments === 'Sum Total') {
            return true;
        }
        return false;
    }
    const weekEndStyle = {
        fontWeight: '500',
        //remove underline and change color of text
        textDecoration: 'none',
        color: 'black'
    }
    React.useEffect(() => {
        var proc = props.filter ? props.filter(procurement) : procurement;
        var cols = [];
        cols.push({
            name: 'myobid',
            label: 'Order No.',
            options: {
                customBodyRenderLite: (dataIndex) => {
                    if (checkIfWeekTotal(dataIndex)) {
                        return <div style={weekEndStyle}>{proc[dataIndex]?.myobid}</div>
                    } else {
                        var tenant = process.env.REACT_APP_MYOB_TENANT ? process.env.REACT_APP_MYOB_TENANT : 'AOCLive';
                        return <Link href={'https://aoc.myobadvanced.com/Main?CompanyID=' + tenant + '&ScreenId=PO301000&OrderType=RO&OrderNbr=' + proc[dataIndex]?.myobid} target='_blank'>{proc[dataIndex]?.myobid}</Link>
                    }
                },
                sortCompare: reverseSort
            }
        })

        if (props.duplicatePO) {
            cols.push(
                {
                    name: 'duplicate',
                    label: ' ',
                    options: {
                        customBodyRenderLite: (dataIndex) => {
                            return <IconButton aria-label="cci" onClick={props.handleDuplicate() ? props.handleDuplicate(proc, dataIndex) : () => { }}>
                                <ContentCopyIcon />
                            </IconButton>
                        },
                    }
                }
            );
        }
        if (props.notReceived) {
            cols.push(
                {
                    name: 'promisedDate',
                    label: 'Use Date',
                    options: {
                        sortCompare: dateSort,
                        sortDescFirst: true,
                        filterType: 'multiselect'
                    }
                }
            );
        } else {
            cols.push({
                name: 'orderDate',
                label: 'Pick Up Date',
                options: {
                    sortCompare: dateSort,
                    sortDescFirst: true,
                    filterType: 'multiselect',
                    customBodyRenderLite: (dataIndex) => {
                        if (checkIfWeekTotal(dataIndex)) {
                            return <div style={weekEndStyle}>{proc[dataIndex]?.orderDate}</div>
                        } else {
                            return <div >{proc[dataIndex]?.orderDate}</div>
                        }
                    },
                }
                //options: props.dayToggle ? { filterList: daySelection !== null ? [dateFormat(generateDates(props.startDate, props.endDate)[daySelection])] : [] } : {}
            });
        }


        cols.push({
            name: 'name',
            label: 'Name',
            options: props.farmerFilter ? { filterList: [props.farmerFilter] } : {},

        });

        if (props.notReceived) {
            cols.push(
                {
                    name: 'quickSummary',
                    label: 'Order'
                }
            );
        }
        cols.push({
            name: 'estuaries',
            label: 'Estuary',
        });
        cols.push({
            name: 'status',
            label: 'Status',

        });
        if (props.showQA) {

            cols.push({
                name: 'qaRec',
                label: 'QA and Receival',
                options: props.showQA ? {
                    customBodyRenderLite: (dataIndex) => {
                        return <span>
                            {(proc[dataIndex]?.qaId && (proc[dataIndex].qaId !== 0)) ? <ViewQAButton access={props.access} id={proc[dataIndex].qaId} recId={proc[dataIndex].receivalId} /> : null}
                            {(proc[dataIndex]?.receivalId && (proc[dataIndex].receivalId !== 0)) ? <ViewReceivalButton access={props.access} id={proc[dataIndex].receivalId} /> : null}
                        </span>
                    }

                } : {}
            });
        }
        if (props.dashboard || props.long) {
            cols = [...cols, ...oysterCols];
        }
        if (props.dashboard || props.long || props.notReceived) {
            cols.push(
                {
                    name: 'warehouse',
                    label: 'Warehouse',
                    options: props.warehouseFilter ? { filterList: [props.warehouseFilter] } : { filterList: [] }

                }
            );
        }

        cols.push(
            {
                name: 'comments',
                label: 'Comments',
                options: {
                    customBodyRenderLite: (dataIndex) => {
                        if (checkIfWeekTotal(dataIndex)) {
                            return <div style={weekEndStyle}>{proc[dataIndex]?.comments}</div>
                        } else {
                            return <div >{proc[dataIndex]?.comments}</div>
                        }
                    },
                }
            }
        );
        if (!props.notReceived) {
            cols.push(
                {
                    name: 'promisedDate',
                    label: 'Use Date',
                    options: {
                        sortCompare: reverseSort,
                        filterType: 'multiselect',
                        customBodyRenderLite: (dataIndex) => {
                            if (checkIfWeekTotal(dataIndex)) {
                                return <div style={weekEndStyle}>{numberFormat(proc[dataIndex]?.promisedDate)}</div>
                            } else {
                                return <div >{proc[dataIndex]?.promisedDate}</div>
                            }
                        },
                    }
                }
            );
        } else {
            cols.push({
                name: 'orderDate',
                label: 'Pick Up Date',
                options: {
                    sortCompare: reverseSort,
                    filterType: 'multiselect',
                    customBodyRenderLite: (dataIndex) => {
                        if (checkIfWeekTotal(dataIndex)) {
                            return <div style={weekEndStyle}>{proc[dataIndex]?.orderDate}</div>
                        } else {
                            return <div >{proc[dataIndex]?.orderDate}</div>
                        }
                    },
                }
                //options: props.dayToggle ? { filterList: daySelection !== null ? [dateFormat(generateDates(props.startDate, props.endDate)[daySelection])] : [] } : {}
            });
        }
        setColumns(colRemoval(proc, cols, ['duplicate', 'qaRec', 'week']));
    }, [props.farmerFilter, props.warehouseFilter, procurement, grades, props.filter])


    var oysterCols = grades.map((og) => {
        var matchAbv = props.dashboard ? og.abv.toLowerCase() : og.abv;
        return {
            name: matchAbv,
            label: og.abv,
            options: {
                filter: false,
                setCellProps: () => ({
                    align: "right"
                }),
                customBodyRenderLite: (dataIndex) => {
                    var proc = props.filter ? props.filter(procurement) : procurement;
                    if (proc) {
                        if (proc[dataIndex]) {
                            if (proc[dataIndex][matchAbv]) {
                                if (checkIfWeekTotal(dataIndex)) {
                                    return <div style={weekEndStyle}>{numberFormat(proc[dataIndex][matchAbv])}</div>
                                } else {
                                    return numberFormat(proc[dataIndex][matchAbv]);
                                }
                            }
                        }
                    }
                    return 0;
                }
            }
        }
    })
    const handleTableChange = (init) => (action, tableState) => {
        var proc = props.filter ? props.filter(procurement) : procurement;
        const totalAmount = calculateTotalSum(proc, tableState.displayData);
        var change = false;
        for (var i = 0; i < totalAmount.length; i++) {

            if (Number.isNaN(totalAmount[i]) && Number.isNaN(total[i])) {
            } else {
                if (totalAmount[i] !== total[i]) {
                    change = true;
                }
            }
        }
        if (init || change) {
            setTotal(totalAmount);

        }
    }

    const calculateTotalSum = (data, displayData) => {
        var totArr = [];
        if (data && data.length > 0) {
            for (var i = 0; i < columns.length; i++) {
                totArr.push(displayData.map((a) => {
                    var res = data[a.dataIndex][columns[i].name];
                    if (Number(res)) {
                        return res;
                    } else {
                        return '';
                    }
                }).reduce((a, b) => {
                    if (Number(b) || (Number(b) === 0)) {
                        return (a += Number(b))
                    } else {
                        return '';
                    }
                }, 0));
            }
        }
        return totArr;
    };
    const options = {
        filterType: 'dropdown',
        selectableRowsHideCheckboxes: true,
        fixedHeader: true,
        tableBodyMaxHeight: props.height ? props.height : '600px',
        responsive: 'standard',
        rowsPerPage: 1000,
        onRowClick: props.onClick ? props.onClick(procurement) : (() => { }),

    };


    const components = {
        ExpandButton: function (props) {
            if (props.dataIndex) {
                if (checkIfWeekTotal(props.dataIndex)) {
                    return <div></div>;
                }
            }
            return <ExpandButton {...props} />;
        },
    };

    if (props.showOutcomes) {
        options.expandableRows = true;
        options.expandableRowsHeader = false;
        options.expandableRowsOnClick = true;
        options.isRowExpandable = (dataIndex, expandedRows) => {
            if (checkIfWeekTotal(dataIndex)) {
                return false;
            }
            return true;
        };
        options.renderExpandableRow = (rowData, rowMeta) => {
            const colSpan = rowData.length + 1;
            var lineData = props.filter ? props.filter(procurement)[rowMeta.dataIndex] : procurement[rowMeta.dataIndex];
            var proc = props.filter ? props.filter(procurement) : procurement;
            var procOutputs = lineData.processRuns;
            var gradeList = [];
            var gradeListDeep = [];
            var outcomesTotal = {};
            var poInputs = {};
            var gradeIdsInProc = [];
            for (var i = 0; i < proc.length; i++) {
                console.log(proc)
                //find all the unique grades in the procurement
                for (var j = 0; j < proc[i]?.purchaseOrderLines?.length; j++) {
                    if (!gradeIdsInProc.includes(proc[i].purchaseOrderLines[j].stockItem.gradeId)) {
                        gradeIdsInProc.push(proc[i].purchaseOrderLines[j].stockItem.gradeId);
                    }
                }
            }


            for (var i = 0; i < grades.length; i++) {
                if (gradeIdsInProc.includes(grades[i].id)) {
                    gradeList.push(grades[i].id);
                }
                if (procOutputs[grades[i].id]) {
                    outcomesTotal[grades[i].id] = Object.values(procOutputs[grades[i].id]).reduce((p, c) => { return p + c }, 0);
                    for (var j = 0; j < grades.length; j++) {
                        if (procOutputs[grades[i].id][grades[j].id]) {
                            gradeListDeep.push(grades[j].id);
                        }
                    }

                }

                if (lineData.purchaseOrderLines.find((pol) => (pol.stockItem.gradeId === grades[i].id))) {
                    poInputs[grades[i].id] = lineData.purchaseOrderLines.find((pol) => (pol.stockItem.gradeId === grades[i].id)).ordered;
                }
            }
            console.log(lineData);
            console.log(gradeList);
            var titleIncluded = -1;
            if (props.processRunsLoading) {
                return <TableRow>
                    <TableCell style={{ padding: '0 0 0 15px', fontWeight: 'bold' }} ></TableCell>
                                <TableCell style={{ padding: '0 0 0 15px', fontWeight: 'bold' }} ></TableCell>
                                <TableCell style={{ padding: '0 0 0 15px', fontWeight: 'bold' }} ></TableCell>
                                <TableCell style={{ padding: '0 0 0 15px', fontWeight: 'bold' }} >{titleIncluded === 0 ? 'Processing Outputs' : ''}</TableCell>
                                <TableCell style={{ padding: '0 0 0 15px', fontWeight: 'bold' }} ></TableCell>
                                <TableCell style={{ padding: '0 0 0 15px', fontWeight: 'bold' }} ></TableCell>
                    <TableCell colSpan={colSpan}>
                        <CircularProgress />
                    </TableCell>
                </TableRow>
            }
            return (
                <React.Fragment>
                    {grades.map((gOut) => {
                        if (gradeListDeep.includes(gOut.id)) {
                            titleIncluded++;
                            // var outcomesTotal = Object.values(procOutputs[gOut.id]).reduce((p, c) => { return p + c }, 0);
                            return (<TableRow >
                                <TableCell style={{ padding: '0 0 0 15px', fontWeight: 'bold' }} ></TableCell>
                                <TableCell style={{ padding: '0 0 0 15px', fontWeight: 'bold' }} ></TableCell>
                                <TableCell style={{ padding: '0 0 0 15px', fontWeight: 'bold' }} ></TableCell>
                                <TableCell style={{ padding: '0 0 0 15px', fontWeight: 'bold' }} >{titleIncluded === 0 ? 'Processing Outputs' : ''}</TableCell>
                                <TableCell style={{ padding: '0 0 0 15px', fontWeight: 'bold' }} ></TableCell>
                                <TableCell style={{ padding: '0 0 0 15px', fontWeight: 'bold' }} ></TableCell>
                                <TableCell style={{ padding: '0 0 0 15px' }} >{gOut.abv}</TableCell>
                                {grades.map((g) => {
                                    if (gradeList.includes(g.id)) {
                                        if (procOutputs[g.id] && procOutputs[g.id][gOut.id]) {
                                            return <TableCell style={{ padding: '0px', }} >{numberFormat(procOutputs[g.id][gOut.id]) + ' (' + (procOutputs[g.id][gOut.id] / outcomesTotal[g.id] * 100).toFixed(0) + '%)'}</TableCell>
                                        } else {
                                            return <TableCell style={{ padding: '0px', }} ></TableCell>;
                                        }
                                    } else {
                                        return null;
                                    }
                                })}
                            </TableRow>)
                        } else {
                            return null;
                        }
                    })
                    }
                    <TableRow >
                        <TableCell style={{ padding: '0 0 0 15px', fontWeight: 'bold' }} ></TableCell>
                        <TableCell style={{ padding: '0 0 0 15px', fontWeight: 'bold' }} ></TableCell>
                        <TableCell style={{ padding: '0 0 0 15px', fontWeight: 'bold' }} ></TableCell>
                        <TableCell style={{ padding: '0 0 0 15px', fontWeight: 'bold' }} ></TableCell>
                        <TableCell style={{ padding: '0 0 0 15px', fontWeight: 'bold' }} ></TableCell>
                        <TableCell style={{ padding: '0 0 0 15px', fontWeight: 'bold' }} ></TableCell>
                        <TableCell style={{ padding: '0 0 0 15px', fontWeight: 'bold', borderTop: '2px solid black' }} >Total</TableCell>
                        {grades.map((g) => {
                            if (gradeList.includes(g.id)) {
                                return <TableCell style={{ padding: '0px', fontWeight: 'bold', borderTop: '2px solid black' }} >{numberFormat(outcomesTotal[g.id])}</TableCell>;
                            } else {
                                return null;
                            }
                        })}
                    </TableRow>
                    <TableRow >
                        <TableCell style={{ padding: '0 0 0 15px', fontWeight: 'bold' }} ></TableCell>
                        <TableCell style={{ padding: '0 0 0 15px', fontWeight: 'bold' }} ></TableCell>
                        <TableCell style={{ padding: '0 0 0 15px', fontWeight: 'bold' }} ></TableCell>
                        <TableCell style={{ padding: '0 0 0 15px', fontWeight: 'bold' }} ></TableCell>
                        <TableCell style={{ padding: '0 0 0 15px', fontWeight: 'bold' }} ></TableCell>
                        <TableCell style={{ padding: '0 0 0 15px', fontWeight: 'bold' }} ></TableCell>
                        <TableCell style={{ padding: '0 0 0 15px', fontWeight: 'bold' }} >Variance</TableCell>
                        {grades.map((g) => {
                            if (gradeList.includes(g.id)) {
                                if (poInputs[g.id] && outcomesTotal[g.id]) {
                                    return <TableCell style={{ padding: '0px', fontWeight: 'bold' }} >{numberFormatwithNeg(outcomesTotal[g.id] - poInputs[g.id])}</TableCell>;
                                } else {
                                    return <TableCell style={{ padding: '0px', fontWeight: 'bold' }} ></TableCell>;
                                }
                            } else {
                                return null;
                            }
                        })}
                    </TableRow>
                </React.Fragment>


            );
        }
    }

    if (props.totals) {
        options.customTableBodyFooterRender = (opts) => {
            var proc = props.filter ? props.filter(procurement) : procurement;
            var openTotals = calculateTotalSum(proc, opts.data.filter((dt) => ((dt.data[4] !== 'Completed') && (dt.data[4] !== 'Closed') && (dt.data[4] !== undefined))));
            var CompletedTotals = calculateTotalSum(proc, opts.data.filter((dt) => ((dt.data[4] === 'Completed') || (dt.data[4] === 'Closed') && (dt.data[4] !== undefined))));
            var totals = calculateTotalSum(proc, opts.data.filter((dt) => ((dt.data[4] !== undefined))));
            var sumTotal = totals.reduce((p, c, ind) => { if (grades.find((gr) => (opts.columns[ind].label === gr.abv))) { return (p + c) } else { return p } }, 0);
            var sumCompletedTotal = CompletedTotals.reduce((p, c, ind) => { if (grades.find((gr) => (opts.columns[ind].label === gr.abv))) { return (p + c) } else { return p } }, 0);
            var sumOpenTotal = openTotals.reduce((p, c, ind) => { if (grades.find((gr) => (opts.columns[ind].label === gr.abv))) { return (p + c) } else { return p } }, 0);
            const paddColStyle = {
                color: '#000',
                paddingTop: '1px',
                paddingBottom: '1px'
            }
            return (
                <TableFooter >
                    <TableRow style={{ fontWeight: 'bold' }}>
                        {props.showOutcomes ? (<TableCell style={{
                            fontWeight: 'bold', ...paddColStyle
                        }}>    </TableCell>) : null}
                        {opts.columns.map((col, index) => {
                            if (index === 0) {
                                return <TableCell key={index} style={{
                                    fontWeight: 'bold', ...paddColStyle
                                }}>
                                    Active
                                </TableCell>
                            } else if (index === opts.columns.length - 2) {
                                return <TableCell key={index} style={{
                                    fontWeight: 'bold', ...paddColStyle
                                }}>
                                </TableCell>
                            } else if (index === opts.columns.length - 1) {
                                return <TableCell key={index} style={{
                                    fontWeight: 'bold', ...paddColStyle
                                }}>
                                    {numberFormat(sumOpenTotal)}
                                </TableCell>
                            } else {

                                if (col.display === "true") {
                                    if (col.label.length === 4) {
                                        return (
                                            <TableCell key={index} style={{
                                                ...paddColStyle
                                            }}>
                                                {numberFormat(openTotals[index])}
                                            </TableCell>
                                        );
                                    } else {
                                        return (
                                            <TableCell key={index} style={{
                                                ...paddColStyle
                                            }}>

                                            </TableCell>
                                        );
                                    }
                                }
                            }
                        })}
                    </TableRow>
                    <TableRow style={{ fontWeight: 'bold' }}>
                        {props.showOutcomes ? (<TableCell style={{
                            fontWeight: 'bold', ...paddColStyle
                        }}>    </TableCell>) : null}
                        {opts.columns.map((col, index) => {
                            if (index === 0) {
                                return <TableCell key={index} style={{
                                    fontWeight: 'bold', ...paddColStyle
                                }}>
                                    Completed
                                </TableCell>
                            } else if (index === opts.columns.length - 2) {
                                return <TableCell key={index} style={{
                                    fontWeight: 'bold', ...paddColStyle
                                }}>
                                </TableCell>
                            } else if (index === opts.columns.length - 1) {
                                return <TableCell key={index} style={{
                                    fontWeight: 'bold', ...paddColStyle
                                }}>
                                    {numberFormat(sumCompletedTotal)}
                                </TableCell>
                            } else {

                                if (col.display === "true") {
                                    if (col.label.length === 4) {
                                        return (
                                            <TableCell key={index} style={{
                                                ...paddColStyle
                                            }}>
                                                {numberFormat(CompletedTotals[index])}
                                            </TableCell>
                                        );
                                    } else {
                                        return (
                                            <TableCell key={index} style={{
                                                ...paddColStyle
                                            }}>

                                            </TableCell>
                                        );
                                    }
                                }
                            }
                        })}
                    </TableRow>
                    <TableRow style={{ fontWeight: 'bold' }}>
                        {props.showOutcomes ? (<TableCell style={{
                            fontWeight: 'bold', ...paddColStyle
                        }}>    </TableCell>) : null}
                        {opts.columns.map((col, index) => {
                            if (index === 0) {
                                return <TableCell key={index} style={{
                                    fontWeight: 'bold', ...paddColStyle
                                }}>
                                    Total
                                </TableCell>
                            } else if (index === opts.columns.length - 2) {
                                return <TableCell key={index} style={{
                                    fontWeight: 'bold', ...paddColStyle
                                }}>
                                </TableCell>
                            } else if (index === opts.columns.length - 1) {
                                return <TableCell key={index} style={{
                                    fontWeight: 'bold', ...paddColStyle
                                }}>
                                    {numberFormat(sumTotal)}
                                </TableCell>
                            } else {

                                if (col.display === "true") {
                                    if (col.label.length === 4) {
                                        return (
                                            <TableCell key={index} style={{
                                                ...paddColStyle
                                            }}>
                                                {numberFormat(totals[index])}
                                            </TableCell>
                                        );
                                    } else {
                                        return (
                                            <TableCell key={index} style={{
                                                ...paddColStyle
                                            }}>

                                            </TableCell>
                                        );
                                    }
                                }
                            }
                        })}
                    </TableRow>
                </TableFooter>
            );
        }

        //options.onTableChange = handleTableChange(false);
        //options.onTableInit = handleTableChange(true);
        //options.customFooter = (count, page, rowsPerPage, changeRowsPerPage, changePage) => {
        //    return (
        //        <TableFooter>
        //            <TableRow>
        //                {total.map((c, ind) => {
        //                    if (ind > (total.length-3)) {
        //                        return <TableCell></TableCell>

        //                    } else if (ind !== 0) {
        //                        return <TableCell>{c ? c : ''}</TableCell>

        //                    } else { 
        //                        return <TableCell>Total</TableCell>
        //                    }
        //                })}
        //            </TableRow>
        //        </TableFooter>
        //    );
        //};
    }
    if (props.oldInfo) {
        options.setRowProps = (row, dataIndex, rowIndex) => {
            var proc = props.filter ? props.filter(procurement) : procurement;
            if (proc[dataIndex].myobid === 'Week Total') {
                return {
                    style: {
                        background: 'rgba(124, 136, 124, 0.58)',
                        fontWeight: 'bold',
                        //remove underline and change color of text
                        textDecoration: 'none',
                        color: 'black'
                    }
                }
            };
            if (row[4] === ("Completed")) {
                return {
                    style: { background: theme.palette.oysterProducts.completed }
                };
            } else if (row[4] === ("In Transit")) {
                return {
                    style: { background: theme.palette.oysterProducts.shipping }
                };
            } else if (row[4] === ("Open")) {
                return {
                    style: { background: theme.palette.oysterProducts.open }
                };
            } else if (row[4] === ("Awaiting Release")) {
                return {
                    style: { background: 'rgba(152, 251, 152,0.3)' }
                };
            } else if (row[4] === ("Canceled")) {
                return {
                    style: { background: theme.palette.oysterProducts.cancelled }
                };
            } else if (row[4] === ("Closed")) {
                return {
                    style: { background: theme.palette.oysterProducts.cancelled }
                };
            }
        }
    }
    React.useEffect(() => {
        const abortController = new AbortController();
        setProcurement([]);

        getProcurementData(props.location, textfieldDateFormat(props.startDate), textfieldDateFormat(props.endDate), abortController);
        return () => {
            abortController.abort();
        };
    }, [props.location, props.startDate, props.endDate, reload, daySelection, props.externalReload]);

    var getProcurementData = async (location, startDate, endDate, abortController) => {
        setLoading(true);
        var data = [];
        if (props.notReceived) {
            //getnotreceived
            data = await getAPIDataParamsReturn('/purchaseorder/getnotreceived', (props.pickupDate ? { UsePickupDate: true } : { startDate, endDate }), abortController);
        } else {
            if (props.dashboard) {
                var params = '';
                if (daySelection.length > 1) {
                    params = new URLSearchParams({
                        Location: location,
                        startDate: textfieldDateFormat(generateDates(startDate, endDate)[daySelection[0]]),
                        endDate: textfieldDateFormat(generateDates(startDate, endDate)[daySelection[daySelection.length - 1]])
                    })
                } else {
                    params = new URLSearchParams({
                        Location: location,
                        startDate: textfieldDateFormat(generateDates(startDate, endDate)[daySelection[0]])
                    })
                }
                data = await getAPIDataParamsReturn('/ProcurementDashboard/PurchaseOrders', params, abortController);

            } else {
                data = await handlePagination(fetch, uri + ('/purchaseorder/Get'), startDate, endDate, { location, UsePromisedDate: !!props.oldInfo }, abortController);
            }

        }
        var tempD = [];
        tempD = data;
        var sortData = [];
        var multiplier = 1;
        if (props.reverseSort) {
            multiplier = -1;
        }
        if (props.sortOrderNo) {
            sortData = tempD.sort((a, b) => {
                return (a.myobid) > (b.myobid) ? multiplier * -1 : multiplier * 1
            })
        } else {
            sortData = tempD.sort((a, b) => {
                return new Date(a.orderDate) > new Date(b.orderDate) ? multiplier * -1 : multiplier * 1
            })
        }

        var newData = sortData.map((po) => {
            var nObj = { ...po };
            if (!props.dashboard) {
                nObj.name = (nObj.supplier ? nObj.supplier.name : '');
            }
            if (!props.dashboard) {
                nObj.estuaries = (nObj.supplier ? nObj.supplier.estuaries : '');
            }

            nObj.orderDate = dateFormat(nObj.orderDate);
            nObj.promisedDate = dateFormat(nObj.promisedDate);
            if (nObj.purchaseOrderLines) {
                for (var i = 0; i < nObj.purchaseOrderLines.length; i++) {
                    if (nObj.purchaseOrderLines[i].stockItem) {
                        if (props.notReceived) {
                            if (i === 0) {
                                nObj.quickSummary = nObj.purchaseOrderLines[i].stockItem.myobid + ':' + nObj.purchaseOrderLines[i].ordered;
                            } else {

                                nObj.quickSummary = nObj.quickSummary + ', ' + nObj.purchaseOrderLines[i].stockItem.myobid + ':' + nObj.purchaseOrderLines[i].ordered;
                            }
                        }
                        nObj[nObj.purchaseOrderLines[i].stockItem.myobid] = (nObj[nObj.purchaseOrderLines[i].stockItem.myobid] ? nObj[nObj.purchaseOrderLines[i].stockItem.myobid] : 0) + nObj.purchaseOrderLines[i].ordered;
                    }
                    if (nObj.purchaseOrderLines[i].warehouse) {
                        if (i === 0) {
                            nObj.warehouse = nObj.purchaseOrderLines[i].warehouse?.description;
                        }
                    }

                }
            }
            return nObj;
        })
        //filter out cancelled status orders   
        newData = newData.filter((po) => {
            return po.status !== 'Canceled';
        })
        setLoading(false);
        setProcurement(newData);
    }
    return (
        <DataTableStandard
            data={props.filter ? props.filter(procurement) : procurement}
            title={<div style={props.dayToggle ? { paddingTop: '15px' } : {}}><div>Purchase Orders</div>{props.dayToggle ? <DayToggle nextWeek={props.nextWeek} lastWeek={props.lastWeek} daySelection={daySelection} setDaySelection={setDaySelection} days={generateTextDays(props.startDate, props.endDate)} /> : ''}</div>}
            columns={columns}
            options={options}
            returnSearch={!loading}
            reload={reloadFunc}
            shrink={props.shrinkTable}
            components={components}
        />
    );
};

export default PurchaseOrders;
